import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  getPackagePriceFromCart,
  getSubTotalWithDiscountFromCart,
} from "../../utils/helpers";
import { getDiscountFromDBByCode } from "../../api/apiCalls";

export default function CartSummaryContents({ cart, setCart }) {
  const [discountCodeForm, setDiscountCodeForm] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [submittingDiscount, setSubmittingDiscount] = useState(false);
  const [packagePrice, setPackagePrice] = useState(
    getPackagePriceFromCart(cart)
  );
  const [discountSavings, setDiscountSavings] = useState(0);
  const [subTotal, setSubTotal] = useState(
    packagePrice +
      cart.addOns.reduce((acc, addOn) => acc + addOn.price * addOn.quantity, 0)
  );
  const [discountedSubTotal, setDiscountedSubTotal] = useState(subTotal);
  const [salesTaxAmount, setSalesTaxAmount] = useState(
    subTotal * cart.tournament.localSalesTax
  );
  const [totalAmount, setTotalAmount] = useState(subTotal + salesTaxAmount);

  const handleDiscountCodeSubmit = async () => {
    // console.log("discountCode", discountCodeForm);
    setSubmittingDiscount(true);
    setDiscountError("");

    const discountMatch = await getDiscountFromDBByCode(discountCodeForm.toUpperCase());
    // console.log("discountMatch", discountMatch);

    setSubmittingDiscount(false);

    if (discountMatch) {
      // Check if discount code is expired
      const expirationDate = new Date(discountMatch.expirationDate);
      const comparisonDate = new Date();

      if (expirationDate < comparisonDate) {
        setDiscountError("This discount code has expired!");
      } else {
        setDiscountError("");
        setCart((prevCart) => ({
          ...prevCart,
          discount: discountMatch,
        }));
      }
    } else {
      setDiscountError("Invalid discount code!");
      setCart((prevCart) => ({
        ...prevCart,
        discount: {},
      }));
    }
  };

  useEffect(() => {
    if (cart.addOns && cart.addOns.length > 0) {
      const updatedAddOns = cart.addOns.map((addOn) => ({
        ...addOn,
        quantity: cart.tournament.numberOfDays,
      }));

      setCart((prevCart) => ({
        ...prevCart,
        addOns: updatedAddOns,
      }));
    }
  }, [cart.tournament.numberOfDays]);

  useEffect(() => {
    if (!cart || !cart.tournament || !cart.addOns) return;

    const newPackagePrice = getPackagePriceFromCart(cart);
    const newSubTotal =
      newPackagePrice +
      cart.addOns.reduce((acc, addOn) => acc + addOn.price * addOn.quantity, 0);

    const { subTotalWithDiscount, amountSaved } =
      getSubTotalWithDiscountFromCart(cart);

    const newSalesTaxAmount =
      subTotalWithDiscount * cart.tournament.localSalesTax;
    const newTotalAmount = subTotalWithDiscount + newSalesTaxAmount;

    setPackagePrice(newPackagePrice);
    setSubTotal(newSubTotal);
    setDiscountedSubTotal(subTotalWithDiscount);
    setDiscountSavings(amountSaved);
    setSalesTaxAmount(newSalesTaxAmount);
    setTotalAmount(newTotalAmount);
  }, [cart, cart.discount?.code, cart.tournament.localSalesTax]);

  return (
    <div>
      <div className="flex px-4 py-6 sm:px-6">
        <div className="flex-shrink-0">
          <a
            href={cart.tournament.href}
            className="font-medium text-gray-700 hover:text-gray-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={cart.tournament.imageSrc}
              alt={`${cart.tournament.name} ${cart.package.name}`}
              className="w-20 rounded-md"
            />
          </a>
        </div>

        <div className="ml-6 flex flex-1 flex-col">
          <div className="flex">
            <div className="min-w-0 flex-1">
              <h4 className="text-sm">{cart.tournament.name}</h4>
              <p className="mt-1 text-sm text-gray-500">
                {cart.tournament.date}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {cart.tournament.city}, {cart.tournament.state}
              </p>
            </div>
          </div>

          <div className="flex flex-1 items-end justify-between pt-2">
            <h4 className="text-sm">{cart.package.name}</h4>
            <p className="mt-1 text-sm font-medium text-gray-900">
              $
              {packagePrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          {cart.addOns.map((addOn) => (
            <div
              key={addOn.id}
              className="flex flex-1 items-end justify-between pt-2"
            >
              <h4 className="text-sm">
                {addOn.name} ({addOn.quantity})
              </h4>
              <p className="mt-1 text-sm font-medium text-gray-900">
                $
                {(addOn.quantity * addOn.price).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="sticky bottom-0 flex-none border-t border-gray-200 bg-white p-6">
        <label
          htmlFor="discount-code"
          className="block text-sm font-medium text-gray-700"
        >
          Discount code
        </label>
        <div className="mt-1 flex space-x-4">
          <input
            id="discount-code"
            name="discount-code"
            type="text"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
            value={discountCodeForm}
            onChange={(e) => setDiscountCodeForm(e.target.value)}
          />
          <button
            type="button"
            onClick={() => handleDiscountCodeSubmit()}
            className={`w-20 min-w-20 rounded-md px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${
              discountCodeForm
                ? "bg-ttBlue-200 text-ttBlue-600 hover:bg-ttBlue-300 focus:ring-ttBlue-500 focus:ring-offset-ttBlue-50"
                : "bg-gray-200 text-gray-400 cursor-not-allowed"
            }`}
            disabled={!discountCodeForm}
          >
            {submittingDiscount ? (
              <ClipLoader size={12} color="text-ttBlue-600" />
            ) : (
              "Apply"
            )}
          </button>
        </div>
        {discountError && (
          <p className="mt-2 text-sm text-red-600">{discountError}</p>
        )}
      </div>

      <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
        <div className="flex items-center justify-between">
          <dt className="text-sm">Subtotal</dt>
          <dd className="text-sm font-medium text-gray-900">
            $
            {subTotal.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </dd>
        </div>
        {cart.discount?.code && (
          <>
            <div className="flex items-center justify-between">
              <dt className="text-sm">
                Discount
                <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                  {cart.discount?.code}
                </span>
              </dt>
              <dd className="text-sm font-medium text-gray-900">
                -$
                {discountSavings.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </dd>
            </div>
            {/* <div className="flex items-center justify-between">
              <dt className="text-sm">
              Subtotal (with discount)
              </dt>
              <dd className="text-sm font-medium text-gray-900">
                $
                {discountedSubTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </dd>
            </div> */}
          </>
        )}
        <div className="flex items-center justify-between">
          <dt className="text-sm">Taxes</dt>
          <dd className="text-sm font-medium text-gray-900">
            $
            {salesTaxAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </dd>
        </div>
        <div className="flex items-center justify-between border-t border-gray-200 pt-6">
          <dt className="text-base font-medium">Total</dt>
          <dd className="text-base font-medium text-gray-900">
            $
            {totalAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </dd>
        </div>
      </dl>
    </div>
  );
}
