import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView } from './analytics'; // Assuming this is your GA4 logging function

const RouteChangeListener = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]); // Re-run this effect when the location changes

  return null; // This component doesn't render anything
};

export default RouteChangeListener;
