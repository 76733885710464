import { useState } from "react";
import Hero from "../components/landing/Hero";
import TopNav from "../components/shared/TopNav";
import Feature from "../components/landing/Feature";
import FAQ from "../components/landing/FAQ";
import LogoCloud from "../components/landing/LogoCloud";
import Footer from "../components/shared/Footer";
import Banner from "../components/landing/Banner";
import Process from "../components/landing/Process";
import SlideoverCalculator from "../components/landing/SlideoverCalculator";

export default function Home({
  cart,
  reservationStages,
  setCart,
  setReservationStages,
  searchQuery,
  setSearchQuery,
}) {
  const [slideoverCalculatorOpen, setSlideoverCalculatorOpen] = useState(false);

  return (
    <div>
      <Banner />
      <Hero
        cart={cart}
        reservationStages={reservationStages}
        setCart={setCart}
        setReservationStages={setReservationStages}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Feature
        slideoverCalculatorOpen={slideoverCalculatorOpen}
        setSlideoverCalculatorOpen={setSlideoverCalculatorOpen}
      />
      <Process />
      <LogoCloud />
      <FAQ />
      <SlideoverCalculator
        slideoverCalculatorOpen={slideoverCalculatorOpen}
        setSlideoverCalculatorOpen={setSlideoverCalculatorOpen}
      />
    </div>
  );
}
