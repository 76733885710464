const filterTournamentsBySearch = (query, tournamentList) => {
  if (query === "") {
    return tournamentList;
  } else {
    return tournamentList.filter((tourney) => {
      return (
        tourney.name.toLowerCase().includes(query.toLowerCase()) ||
        tourney.city.toLowerCase().includes(query.toLowerCase()) ||
        tourney.state.toLowerCase().includes(query.toLowerCase()) ||
        tourney.sport.toLowerCase().includes(query.toLowerCase())
      );
    });
  }
};

const sortTournaments = (tournaments, sortBy, sortDirection) => {
  return tournaments.sort((a, b) => {
    // Check if sorting by date
    if (sortBy === "date") {
      const dateA = new Date(a.startTimestamp);
      const dateB = new Date(b.startTimestamp);

      return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    } else {
      // Handle alphabetical sorting
      const valueA = a[sortBy].toLowerCase();
      const valueB = b[sortBy].toLowerCase();

      if (sortDirection === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    }
  });
};

const filterTournamentsByCriteria = (tournaments, filters) => {
  // console.log(tournaments);
  // console.log(filters);

  return tournaments.filter((tournament) => {
    const matchesSport =
      !filters.sport.length ||
      filters.sport.includes(tournament.sport.toLowerCase());
    const matchesGender =
      !filters.gender.length ||
      filters.gender.includes(tournament.gender.toLowerCase());
    const matchesState =
      !filters.state.length || filters.state.includes(tournament.state);
    return matchesSport && matchesGender && matchesState;
  });
};

// TODO this needs to be harmonized with the createPaymentIntent lambda function
const getPackagePriceFromCart = (cart) => {
  const selectedPackage = cart.package.name;

  if (selectedPackage === "Standard Package") {
    return cart.tournament.singlePrice;
  } else if (selectedPackage === "Double Package") {
    return cart.tournament.doublePrice;
  }
};

const getTotalAddOnPriceFromCart = (cart) => {
  let addOnPrice = 0;
  if (cart.addOns.length > 0) {
    for (const addOn in cart.addOns) {
      addOnPrice += (cart.addOns[addOn].price * cart.addOns[addOn].quantity);
    }
  }
  return addOnPrice;
}

const getSubTotalWithDiscountFromCart = (cart) => {
  let subTotalWithDiscount = 0;
  let amountSaved = 0;

  const packagePrice = getPackagePriceFromCart(cart);
  const addOnPrice = getTotalAddOnPriceFromCart(cart);
  const subtotal = packagePrice + addOnPrice;

  // console.log("cart", cart);
  if (cart.discount.code) {
    // console.log("cart.discount", cart.discount);
    if (cart.discount.type.toLowerCase() === "percentage") {
      subTotalWithDiscount = (1 - cart.discount.value) * subtotal;
    } else if (cart.discount.type.toLowerCase() === "fixed_amount") {
      subTotalWithDiscount = subtotal - cart.discount.value;
    }
    amountSaved = subtotal - subTotalWithDiscount;
  } else {
    subTotalWithDiscount = subtotal;
  }
  
  // console.log("subTotalWithDiscount", subTotalWithDiscount);
  // console.log("amountSaved", amountSaved);

  return { subTotalWithDiscount, amountSaved };
}

export {
  filterTournamentsBySearch,
  filterTournamentsByCriteria,
  sortTournaments,
  getPackagePriceFromCart,
  getTotalAddOnPriceFromCart,
  getSubTotalWithDiscountFromCart
};
