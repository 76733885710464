import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import Payments from "./Payments";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import CartSummaryContents from "./CartSummaryContents";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TeamDetails({
  cart,
  setCart,
  setReservationStages,
  reservationStages,
  initialReservationStages,
  newStageSelectHandler,
}) {
  // console.log("TeamDetails>cart: ", cart);

  const navigate = useNavigate();
  // Then inside the component body

  const handleTeamDetailsSubmit = (values) => {
    // console.log("TeamDetails>handleSubmitTeamDetails>values: ", values);
    // console.log("TeamDetails>handleSubmitTeamDetails>cart: ", cart);
    const newCart = { ...cart };
    newCart.team = values;
    setCart(newCart);
    newStageSelectHandler("Step 5", newCart);
  };

  const getValidationSchema = () => {
    const commonValidationSchema = {
      teamName: Yup.string().required("Team name is required"),
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .test(
          "is-valid",
          "Email must be in the format: yourname@example.com",
          (value) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
          }
        ),
      phone: Yup.string()
        .matches(
          /^[0-9]{10}$/,
          "Please enter a 10-digit number, with no hyphens or spaces."
        )
        .required("Phone number is required"),
      addressLine1: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      country: Yup.string()
        .required("Country is required")
        .matches(
          /^[A-Za-z]{2}$/,
          "Country must be exactly 2 alphabetic characters"
        ),
      state: Yup.string()
        .required("State / Province is required")
        .matches(
          /^[A-Za-z]{2}$/,
          "State / Province must be exactly 2 alphabetic characters"
        ),
      postalCode: Yup.string()
        .required("Postal code is required")
        .matches(/^\d{5}$/, "Postal code must be a 5-digit number"),
      teamColor: Yup.string(),
    };
    if (cart.tournament.id === "FtTTnv9Umpm") {
      return Yup.object().shape({
        ...commonValidationSchema,
        teamYear: Yup.string(),
        teamDivision: Yup.string(),
        tournamentName: Yup.string().required("Tournament name is required"),
        tournamentCity: Yup.string().required("Tournament city is required"),
        tournamentDate: Yup.string().required("Tournament date is required"),
      });
    } else if (
      cart.tournament.id === "cFtqjRmLLP7" ||
      cart.tournament.id === "TMDF9hb6pmJ"
    ) {
      return Yup.object().shape({
        ...commonValidationSchema,
        teamYear: Yup.string(),
        teamDivision: Yup.string(),
      });
    } else {
      return Yup.object().shape({
        ...commonValidationSchema,
        teamYear: Yup.string().required("Team year is required"),
        teamDivision: Yup.string().required("Team division is required"),
      });
    }
  };

  // Custom ErrorMessage component with Tailwind CSS styling
  const FormikError = ({ name }) => (
    <ErrorMessage
      name={name}
      component="div"
      className="text-ttOrange-500 text-sm mt-1" // Tailwind classes
    />
  );

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl pb-24 pt-16 lg:max-w-7xl ">
        <h2 className="sr-only">Enter Team Details</h2>
        <Formik
          initialValues={{
            teamName: cart.team?.teamName || "",
            tournamentName: cart.team?.tournamentName || "",
            tournamentCity: cart.team?.tournamentCity || "",
            tournamentDate: cart.team?.tournamentDate || "",
            teamYear: cart.team?.teamYear || "",
            teamDivision: cart.team?.teamDivision || "",
            teamColor: cart.team?.teamColor || "",
            firstName: cart.team?.firstName || "",
            lastName: cart.team?.lastName || "",
            email: cart.team?.email || "",
            phone: cart.team?.phone || "",
            preferredContactMethod:
              cart.team?.preferredContactMethod || "email",
            addressLine1: cart.team?.addressLine1 || "",
            addressLine2: cart.team?.addressLine2 || "",
            city: cart.team?.city || "",
            state: cart.team?.state || "",
            postalCode: cart.team?.postalCode || "",
            country: cart.team?.country || "",
          }}
          validationSchema={getValidationSchema()}
          validateOnMount={true}
          // validateOnChange={true}
          validateOnBlur={true}
        >
          {({ values, isValid, isSubmitting, setSubmitting, handleChange }) => (
            <Form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
              <div>
                {cart.tournament.id === "FtTTnv9Umpm" && (
                  <>
                    <div className="border-b border-gray-200 mb-8 pb-8">
                      <h2 className="text-lg font-medium text-gray-900">
                        Tournament information
                      </h2>
                      <span className="block sm:inline text-xs text-gray-500">
                        Since you chose "Another MD Tournament," we need a
                        little more information about the tournament. We'll
                        contact you with any additional questions we need to
                        identify the tournament.
                      </span>

                      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                        <div className=" mt-4 sm:col-span-2">
                          <label
                            htmlFor="tournamentName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Tournament Name
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              id="tournamentName"
                              name="tournamentName"
                              autoComplete="tournamentName"
                              placeholder={
                                "e.g., Bay Bridge Brawl, Crabfeast, Summer Getaway"
                              }
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                              onChange={(e) => {
                                handleChange(e); // Update the Formik state
                                setCart((prevState) => ({
                                  ...prevState,
                                  team: {
                                    ...prevState.team,
                                    tournamentName: e.target.value,
                                  },
                                }));
                              }}
                            />
                            <FormikError
                              name="tournamentName"
                              component="div"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="tournamentCity"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Tournament City
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="tournamentCity"
                              id="tournamentCity"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                              onChange={(e) => {
                                handleChange(e); // Update the Formik state
                                setCart((prevState) => ({
                                  ...prevState,
                                  team: {
                                    ...prevState.team,
                                    tournamentCity: e.target.value,
                                  },
                                }));
                              }}
                            />
                            <FormikError
                              name="tournamentCity"
                              component="div"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="tournamentDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Dates
                          </label>
                          <div className="mt-1">
                            <Field
                              type="text"
                              name="tournamentDate"
                              id="tournamentDate"
                              placeholder={"e.g., June 23, 2024"}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                              onChange={(e) => {
                                handleChange(e); // Update the Formik state
                                setCart((prevState) => ({
                                  ...prevState,
                                  team: {
                                    ...prevState.team,
                                    tournamentDate: e.target.value,
                                  },
                                }));
                              }}
                            />
                            <FormikError
                              name="tournamentDate"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <h2 className="text-lg font-medium text-gray-900">
                    Team information
                    <span className="block sm:inline sm:ml-2 text-xs text-gray-500">
                      This helps us uniquely identify your team at{" "}
                      {cart.tournament.name}.
                    </span>
                  </h2>

                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    <div className="mt-1 sm:col-span-2">
                      <label
                        htmlFor="teamName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Team name
                      </label>
                      <Field
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                        name="teamName"
                        type="text"
                        onChange={(e) => {
                          handleChange(e); // Update the Formik state
                          setCart((prevState) => ({
                            ...prevState,
                            team: {
                              ...prevState.team,
                              teamName: e.target.value,
                            },
                          }));
                        }}
                      />
                      <FormikError name="teamName" component="div" />
                    </div>
                    {cart.tournament.id !== "cFtqjRmLLP7" &&
                      cart.tournament.id !== "TMDF9hb6pmJ" && (
                        <>
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="teamDivision"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Division
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                id="teamDivision"
                                name="teamDivision"
                                autoComplete="teamDivision"
                                placeholder={"e.g., Varsity, JV, 2028s, Youth"}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                                onChange={(e) => {
                                  handleChange(e); // Update the Formik state
                                  setCart((prevState) => ({
                                    ...prevState,
                                    team: {
                                      ...prevState.team,
                                      teamDivision: e.target.value,
                                    },
                                  }));
                                }}
                              />
                              <FormikError
                                name="teamDivision"
                                component="div"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="teamYear"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Year
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="teamYear"
                                id="teamYear"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                                onChange={(e) => {
                                  handleChange(e); // Update the Formik state
                                  setCart((prevState) => ({
                                    ...prevState,
                                    team: {
                                      ...prevState.team,
                                      teamYear: e.target.value,
                                    },
                                  }));
                                }}
                              />
                              <FormikError name="teamYear" component="div" />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="teamColor"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Color
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="teamColor"
                                id="teamColor"
                                placeholder={"e.g., Red, Blue, Green, etc."}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                                onChange={(e) => {
                                  handleChange(e); // Update the Formik state
                                  setCart((prevState) => ({
                                    ...prevState,
                                    team: {
                                      ...prevState.team,
                                      teamColor: e.target.value,
                                    },
                                  }));
                                }}
                              />
                              <FormikError name="teamColor" component="div" />
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>

                <div className="border-t border-gray-200 mt-8 pt-8">
                  <h2 className="text-lg font-medium text-gray-900">
                    Order contact information
                    <span className="block sm:inline sm:ml-2 text-xs text-gray-500">
                      Who should we contact about this order?
                    </span>
                  </h2>

                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    <div>
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First name
                      </label>
                      <div className="mt-1">
                        <Field
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          name="firstName"
                          type="text"
                          autoComplete="given-name"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                firstName: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="firstName" component="div" />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last name
                      </label>
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="lastName"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                lastName: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="lastName" component="div" />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          autoComplete="email"
                          placeholder={"e.g., name@example.net"}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                email: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="email" component="div" />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone
                      </label>
                      <div className="mt-1">
                        <Field
                          type="text"
                          id="phone"
                          name="phone"
                          autoComplete="tel"
                          maxLength="10"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                phone: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="phone" component="div" />
                      </div>
                    </div>

                    <fieldset>
                      <div className="text-sm font-medium text-gray-700">
                        Preferred contact method
                      </div>
                      <div className="mt-1 space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                          <Field
                            id="email-preferred"
                            name="preferredContactMethod"
                            type="radio"
                            value="email"
                            className="h-4 w-4 border-gray-300 text-ttBlue-600 focus:ring-ttBlue-500"
                            onChange={(e) => {
                              handleChange(e); // Update the Formik state
                              setCart((prevState) => ({
                                ...prevState,
                                team: {
                                  ...prevState.team,
                                  preferredContactMethod: e.target.value,
                                },
                              }));
                            }}
                          />
                          <label
                            htmlFor="email-preferred"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                        </div>
                        <div className="flex items-center">
                          <Field
                            id="phone-preferred"
                            name="preferredContactMethod"
                            type="radio"
                            value="phone"
                            className="h-4 w-4 border-gray-300 text-ttBlue-600 focus:ring-ttBlue-500"
                            onChange={(e) => {
                              handleChange(e); // Update the Formik state
                              setCart((prevState) => ({
                                ...prevState,
                                team: {
                                  ...prevState.team,
                                  preferredContactMethod: e.target.value,
                                },
                              }));
                            }}
                          />
                          <label
                            htmlFor="phone-preferred"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            Phone
                          </label>
                        </div>
                        <div className="flex items-center">
                          <Field
                            id="text-preferred"
                            name="preferredContactMethod"
                            value="text"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-ttBlue-600 focus:ring-ttBlue-500"
                            onChange={(e) => {
                              handleChange(e); // Update the Formik state
                              setCart((prevState) => ({
                                ...prevState,
                                team: {
                                  ...prevState.team,
                                  preferredContactMethod: e.target.value,
                                },
                              }));
                            }}
                          />
                          <label
                            htmlFor="text-preferred"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            Text
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="addressLine1"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Address
                      </label>
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="addressLine1"
                          id="addressLine1"
                          autoComplete="street-address"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                addressLine1: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="addressLine1" component="div" />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="addressLine2"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Apartment, suite, etc.
                      </label>
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="addressLine2"
                          id="addressLine2"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                addressLine2: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="addressLine2" component="div" />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        City
                      </label>
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="city"
                          id="city"
                          autoComplete="address-level2"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                city: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="city" component="div" />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State / Province
                      </label>
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="state"
                          id="state"
                          autoComplete="address-level1"
                          maxLength="2"
                          placeholder={'e.g., "MD" for Maryland'}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                state: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="state" component="div" />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="postalCode"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Postal code
                      </label>
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          autoComplete="postalCode"
                          maxLength="5"
                          placeholder="e.g., 12345"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                postalCode: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="postalCode" component="div" />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="country"
                          id="country"
                          autoComplete="country"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500 sm:text-sm"
                          maxLength="2"
                          placeholder="e.g., US for United States"
                          onChange={(e) => {
                            handleChange(e); // Update the Formik state
                            setCart((prevState) => ({
                              ...prevState,
                              team: {
                                ...prevState.team,
                                country: e.target.value,
                              },
                            }));
                          }}
                        />
                        <FormikError name="country" component="div" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Action Button */}
                <div className="border-t border-gray-200 px-4 mt-8 pt-8 sm:px-6">
                  {!isValid && (
                    <p className="text-sm text-red-500 ">
                      You must add all required team details before checking
                      out.
                    </p>
                  )}
                  <button
                    type="button"
                    disabled={!isValid}
                    onClick={() => {
                      // Handle form submission
                      setSubmitting(false);
                      handleTeamDetailsSubmit(values);
                    }}
                    className={`w-full rounded-md border px-4 py-3 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 ${
                      isValid
                        ? "border-transparent bg-ttNavy-600 text-white hover:bg-ttNavy-700 focus:ring-ttNavy-500"
                        : "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed"
                    }`}
                  >
                    Go to checkout
                  </button>
                </div>
              </div>

              {/* Cart summary */}
              <div className="mt-10 lg:mt-0">
                <h2 className="text-lg font-medium text-gray-900">
                  Cart summary
                </h2>

                <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
                  <h3 className="sr-only">Items in your cart</h3>
                  <CartSummaryContents cart={cart} setCart={setCart} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
