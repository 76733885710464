import ContactBody from "../components/contact/ContactBody";
import Footer from "../components/shared/Footer";
import TopNav from "../components/shared/TopNav";

export default function Contact() {
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <ContactBody />
    </div>
  );
}
