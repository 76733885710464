import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { dbTournaments } from "../../assets/testingData";
import { filterTournamentsBySearch } from "../../utils/helpers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FindTourneyCombobox({ cart, setCart, reservationStages, setReservationStages, setMobileMenuOpen }) {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const [selectedTournament, setSelectedTournament] = useState(null);

  const handleTournamentSelection = (newSelectedTournament) => {
    const previousCart = { ...cart };
    setSelectedTournament(newSelectedTournament);
    setCart({...previousCart, tournament: newSelectedTournament});
  
    const newReservationStages = reservationStages.map((stage) => {
      if (stage.id === "Step 2") {
        return { ...stage, status: "current", active: true };
      } else if (stage.status === "current") {
        return { ...stage, status: "complete", active: false };
      } else {
        return stage;
      }
    });
    
    setReservationStages(newReservationStages);

    // Close mobile menu
    setMobileMenuOpen(false);

    // Call handleNavigation when you want to navigate to /reservations
    navigate("/reservations");
  };

  const filteredTourneys = filterTournamentsBySearch(query, dbTournaments);

  return (
    <Combobox
      as="div"
      value={selectedTournament}
      onChange={handleTournamentSelection}
    >
      {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Assigned to</Combobox.Label> */}
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-ttBlue-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(tournament) => tournament?.name}
          placeholder="Search upcoming tournaments"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredTourneys.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredTourneys.map((tourney) => (
              <Combobox.Option
                key={tourney.name}
                value={tourney}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-ttBlue-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex flex-col">
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {tourney.name}
                      </span>
                      <span
                        className={classNames(
                          "ml-2 truncate text-gray-500",
                          active ? "text-ttBlue-200" : "text-gray-500"
                        )}
                      >
                        {tourney.city}, {tourney.state}
                      </span>
                      <span
                        className={classNames(
                          "ml-2 truncate text-gray-500",
                          active ? "text-ttBlue-200" : "text-gray-500"
                        )}
                      >
                        {tourney.date}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-ttBlue-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
