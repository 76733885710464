import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { customAlphabet } from "nanoid";
import { nolookalikesSafe } from "nanoid-dictionary";
import { SyncLoader } from "react-spinners";
import {
  addOrderToDB,
  addCustomerToDB,
  addOrderItemToDB,
} from "../../api/apiCalls";
import { getPackagePriceFromCart } from "../../utils/helpers";
import TermsModal from "./TermsModal";
import { get } from "react-scroll/modules/mixins/scroller";

const nanoid = customAlphabet(nolookalikesSafe, 11);

export default function CheckoutForm({
  clientSecret,
  cart,
  setReservationStages,
  initialReservationStages,
  setCart,
  searchQuery,
  setSearchQuery,
}) {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false); // State to manage checkbox

  // const [stripeElementsReady, setStripeElementsReady] = useState(false);

  // console.log("CheckoutForm>message: ", message);

  const handleConfirmOrder = async (paymentIntent) => {
    // Turn off spinner
    setIsLoading(false);

    // Order to dynamoDB
    const orderId = nanoid();
    // console.log("CheckoutForm>handleConfirmOrder>orderId: ", orderId);

    // Copy cart to avoid mutating state
    const orderedCart = cart;

    try {
      //function from apiCalls.js
      const addCustomerResponse = await addCustomerToDB(cart.team);
      // console.log(
      //   "CheckoutForm>handleConfirmOrder>addCustomerResponse: ",
      //   addCustomerResponse
      // );

      // Add package to order items
      const packagePrice = getPackagePriceFromCart(cart);
      const addOrderItemResponse = await addOrderItemToDB(
        orderId,
        cart.package.id,
        1,
        packagePrice
      );

      // If any addOns, loop over them and add to order items
      if (cart.addOns.length > 0) {
        for (const addOn in cart.addOns) {
          const addOnItemResponse = await addOrderItemToDB(
            orderId,
            cart.addOns[addOn].id,
            cart.addOns[addOn].quantity,
            cart.addOns[addOn].price
          );
        }
      }

      // console.log("addOrderItemResponse", addOrderItemResponse);

      const addOrderResponse = await addOrderToDB(
        orderId,
        addCustomerResponse.data.id,
        addOrderItemResponse.data.id,
        cart,
        paymentIntent
      );
      // If the functions complete succesfully:
      if (addCustomerResponse.success) {
        if (addOrderResponse.success) {
          if (addOrderResponse.success) {
            // console.log("Customer added to DB");
            // Reset the reservation stages and cart
            setReservationStages(
              initialReservationStages.map((stage) => ({ ...stage }))
            );
            setCart({ tournament: {}, addOns: {}, package: {}, team: {}, discount: {} });
            // Reset search query, just in case it was used
            setSearchQuery("");

            // Navigate to the order summary page with copy of the ordered cart and order form values
            navigate("/OrderSummary", {
              state: {
                orderId: orderId,
                orderedCart: orderedCart,
                paymentIntent: paymentIntent,
              },
            });
          } else {
            // console.error(
            //   "Error adding order item to DB:",
            //   addOrderItemResponse.message
            // );
          }
        } else {
          // console.error("Error adding order to DB:", addOrderResponse.message);
        }
      } else {
        // console.error(
        //   "Error adding customer to DB:",
        //   addCustomerResponse.message
        // );
      }
    } catch (error) {
      //If the function fails, display the error
      // console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   if (!stripe) {
  //     console.log("Stripe.js has not loaded yet.");
  //     return;
  //   }

  //   if (!clientSecret) {
  //     console.log("Client secret has not been set.");
  //     return;
  //   }

  //   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //     console.log(
  //       "stripe.retrievePaymentIntent>paymentIntent: ",
  //       paymentIntent
  //     );
  //     switch (paymentIntent.status) {
  //       case "succeeded":
  //         setMessage("Payment succeeded!");
  //         break;
  //       case "processing":
  //         setMessage("Your payment is processing.");
  //         break;
  //       case "requires_payment_method":
  //         setMessage("Your payment was not successful, please try again.");
  //         break;
  //       default:
  //         setMessage("Something went wrong.");
  //         break;
  //     }
  //   });
  // }, [stripe, clientSecret]);

  const handleSubmit = async (e) => {
    // console.log("CheckoutForm>handleSubmit");
    try {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/OrderSummary`,
          receipt_email: cart.team.email,
        },
        redirect: "if_required",
      });
      // console.log(
      //   "CheckoutForm>handleSubmit>confirmPayment>paymentIntent: ",
      //   paymentIntent
      // );

      // console.log("CheckoutForm>handleSubmit>confirmPayment>error: ", error);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
      if (error) {
        // console.error(error);
        // handleError();
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        // console.log("Payment succeeded");
        handleConfirmOrder(paymentIntent);
      } else {
        // console.log("Payment failed");
        // handleOther();
      }
    } catch (error) {
      // console.log("error: ", error);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage(
          "There was a problem charging your card. Please check your payment details and try again."
        );
      }

      setIsLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <h2 className="text-lg font-medium text-gray-900 mb-4">
        Payment Information
      </h2>
      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <div className="border-t border-gray-200  mt-8 pt-4 ">
        {/* Waiver checkbox and link to terms */}
        <div className="mt-2 flex justify-start">
          <label className="flex items-center text-sm">
            <input
              id="terms-checkbox"
              name="terms-checkbox"
              type="checkbox"
              className="mr-2 h-4 w-4 rounded border-gray-300 text-ttBlue-600 focus:ring-ttBlue-600"
              checked={isTermsChecked}
              onChange={(e) => setIsTermsChecked(e.target.checked)}
            />
            In order to complete your purchase, you must agree to the
            <button
              type="button"
              className="ml-1 font-medium text-ttOrange-600 underline hover:text-ttOrange-800"
              onClick={() => setTermsModalOpen(true)}
            >
              terms
            </button>
            {' '}.
          </label>
        </div>
        <div className="mt-4 flex justify-center">
          <button
            disabled={isLoading || !stripe || !elements || !isTermsChecked}
            id="submit"
            className={`w-1/2 rounded-md border px-4 py-3  text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 ${
              isLoading || !stripe || !elements || !isTermsChecked
                ? "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed"
                : "border-transparent bg-ttNavy-600 text-white hover:bg-ttNavy-700 focus:ring-ttNavy-500"
            }`}
            // onClick={handleSubmit}
          >
            {isLoading ? <SyncLoader size={8} color="#f9704b" /> : "Pay now"}
          </button>
        </div>
      </div>
      {/* Show any error or success messages */}
      {message && !isLoading && (
        <div
          id="payment-message"
          className="mt-4 flex justify-center text-sm text-ttOrange-400"
        >
          {message}
        </div>
      )}
      {termsModalOpen && (
        <TermsModal
          isOpen={termsModalOpen}
          onClose={() => setTermsModalOpen(false)}
        />
      )}
    </form>
  );
}
