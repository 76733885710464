import { Link } from "react-router-dom";
import broomIcon from "../../assets/icons/broom.svg";
import eventIcon from "../../assets/icons/event.svg";
import canopyIcon from "../../assets/icons/canopy.svg";
import trophyIcon from "../../assets/icons/trophy.svg";

const steps = [
  {
    id: 1,
    name: "Book your tournament.",
    description: "Pick your tournament and choose a package.",
    imageSrc: eventIcon,
    href: "/Reservations",
  },
  {
    id: 2,
    name: "Let us take care of setup.",
    description: "We'll have your tent set up in a prime location on game day.",
    imageSrc: canopyIcon,
  },
  {
    id: 3,
    name: "Enjoy the tournament.",
    description:
      "Show up and enjoy a hassle-free experience with your family and team.",
    imageSrc: trophyIcon,
  },
  {
    id: 4,
    name: "Head home.",
    description:
      "We handle all the breakdown and cleanup so you can get on your way.",
    imageSrc: broomIcon,
  },
];

export default function Process() {
  return (
    <div id="process" className="bg-ttNavy-500">
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div className="max-w-4xl">
          <h2 className="text-4xl font-bold tracking-tight text-gray-200">
            We've got you covered from booking to last whistle.
          </h2>
          <p className="mt-4 text-gray-400">
            Our concierge process only takes a few minutes to finish. It's
            simple: choose a tournament, pick a package, and tell us about your
            team. Then we'll take care of everything else so you can focus on
            the game.
          </p>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8">
          {steps.map((step) => {
            const content = (
              <div key={step.id} className="">
                <img src={step.imageSrc} alt="" className="h-24 w-auto ml-1" />
                <h3 className="mt-6 text-base font-semibold text-ttBlue-500">
                  {step.name}
                </h3>
                <p className="mt-2 text-sm text-gray-200">{step.description}</p>
              </div>
            );
            return step.id === 1 ? (
              <Link to="/reservations" key={step.id}>{content}</Link>
            ) : (
              content
            );
          })}
        </div>

        
        <div className="button hidden sm:mt-12 sm:flex sm:justify-center">
          <Link to="#faq">
            <button
              type="button"
              className="relative rounded-full px-3 py-1 text-md leading-6 text-gray-200 ring-1 ring-ttOrange-500/10 hover:ring-ttOrange-500/20"
            >
              Want to know more? Take a look at some{" "}
              <span
                className="font-semibold text-ttOrange-500"
                aria-hidden="true"
              >
                frequently asked questions{" "}
              </span>{" "}
              <span aria-hidden="true">&rarr;</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
