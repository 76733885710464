import { useEffect } from "react";
export default function SimpleStepper({
  reservationStages,
  setReservationStages,
  activeStep,
  activeStepName,
  newStageSelectHandler,
  setShouldWiggle,
  shouldWiggle,
  cart,
}) {

  // // Debugging: Check if cart updates are detected
  // useEffect(() => {
  //   console.log("SimpleStepper> Cart updated:", cart);
  // }, [cart]);

  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {reservationStages.map((step) => (
          <li key={step.name} className="md:flex-1">
            <div
              onClick={() => {
                newStageSelectHandler(step.id, cart);
                setTimeout(() => setShouldWiggle(false), 500);
              }}
              className={`group flex flex-col py-2 pl-4 md:pb-0 md:pl-0 md:pt-4 ${
                step.active
                  ? "border-l-4 border-ttNavy-600 hover:border-ttNavy-800 md:border-l-0 md:border-t-4"
                  : "border-l-4 border-gray-200 hover:border-gray-300 md:border-l-0 md:border-t-4"
              } ${shouldWiggle ? "wiggle" : ""}`}
            >
              <span
                className={`text-sm font-medium ${
                  step.active
                    ? "text-ttNavy-600 group-hover:text-ttNavy-800"
                    : "text-gray-500 group-hover:text-gray-700"
                } ${shouldWiggle ? "wiggle" : ""}`}
              >
                {step.id}
              </span>
              <span className="text-sm font-medium">{step.name}</span>
              <span className="text-sm text-ttNavy-500 font-medium">
                {step.id === "Step 1" && cart.tournament.name}
              </span>
              <span className="text-sm text-ttNavy-500 font-medium">
                {step.id === "Step 2" && cart.package.name}
              </span>
              {/* Only display add-ons for Step 3 */}
              <span className="text-sm text-ttNavy-500 font-medium">
                {step.id === "Step 3" &&
                  (cart.addOns && cart.addOns.length > 0
                    ? cart.addOns.map((addOn) => `${addOn.name}`).join(", ")
                    : "")}
              </span>

              <span className="text-sm text-ttNavy-500 font-medium">
                {step.id === "Step 4" && cart.team.teamName}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
