import React, { useState } from "react";
import { Dialog, Disclosure, Menu, Popover } from "@headlessui/react";
import { XMarkIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/20/solid";

const sortOptions = [
  { name: "Date", value: "date" },
  { name: "Alphabetical (Name)", value: "name" },
  { name: "Alphabetical (City)", value: "city" },
];

const filtersConfig = [
  //   {
  //     id: "sport",
  //     name: "Sport",
  //     options: [
  //       { value: "lacrosse", label: "Lacrosse" },
  //       { value: "soccer", label: "Soccer" },
  //     ],
  //   },
  {
    id: "gender",
    name: "Gender",
    options: [
      { value: "boys", label: "Boys" },
      { value: "girls", label: "Girls" },
    ],
  },
  {
    id: "state",
    name: "State",
    options: [
      { value: "MD", label: "MD" },
      { value: "FL", label: "FL" },
    ],
  },
];

export default function FilterSortBar({
  filters,
  setFilters,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
}) {
  const [open, setOpen] = useState(false);

  const handleFilterChange = (sectionId, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [sectionId]: prevFilters[sectionId].includes(value)
        ? prevFilters[sectionId].filter((v) => v !== value)
        : [...prevFilters[sectionId], value],
    }));
  };

  const handleSortChange = (sortOption) => {
    if (sortBy === sortOption) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(sortOption);
      setSortDirection("asc");
    }
  };

  return (
    <div className="bg-gray-50 rounded-b-md">
      {/* Mobile filter dialog */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="relative z-40 sm:hidden"
      >
        <Dialog.Backdrop className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0" />

        <div className="fixed inset-0 z-40 flex">
          <Dialog.Panel
            transition
            className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
          >
            <div className="flex items-center justify-between px-4">
              <h2 className="text-lg font-medium text-gray-900">Filters</h2>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-ttBlue-500"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>

            {/* Filters */}
            <form className="mt-4">
              {filtersConfig.map((section) => (
                <Disclosure key={section.name} as="div" className="px-4 py-6">
                  <h3 className="-mx-2 -my-3 flow-root">
                    <Disclosure.Button className="group flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                      <span className="font-medium text-gray-900">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180"
                        />
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">
                    <div className="space-y-6">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            checked={
                              filters[section.id]?.includes(option.value) ||
                              false
                            }
                            onChange={() =>
                              handleFilterChange(section.id, option.value)
                            }
                            id={`filter-mobile-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-ttBlue-600 focus:ring-ttBlue-500"
                          />
                          <label
                            htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                            className="ml-3 text-sm text-gray-500"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Disclosure>
              ))}
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>

      <div className="mx-auto max-w-3xl px-4 text-center lg:max-w-7xl">
        <section aria-labelledby="filter-heading" className="py-2">
          <h2 id="filter-heading" className="sr-only">
            Product filters
          </h2>

          <div className="flex items-center justify-between">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  />
                </Menu.Button>
              </div>

              <Menu.Items
  transition
  className="absolute left-0 z-10 mt-2 w-60 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
>
  <div className="py-1">
    {sortOptions.map((option) => (
      <Menu.Item key={option.value}>
        <button
          onClick={() => handleSortChange(option.value)}
          className={`flex items-center px-4 py-2 text-sm font-medium text-gray-900 w-full ${
            sortBy === option.value ? "bg-gray-100" : ""
          }`}
        >
          {/* Reserve space for the icon */}
          <span className="inline-block w-4 h-4 mr-2">
            {sortBy === option.value && (
              <>
                {sortDirection === "asc" ? (
                  <ArrowUpIcon className="h-4 w-4 text-gray-500" />
                ) : (
                  <ArrowDownIcon className="h-4 w-4 text-gray-500" />
                )}
              </>
            )}
          </span>
          {option.name}
        </button>
      </Menu.Item>
    ))}
  </div>
</Menu.Items>

            </Menu>

            <button
              type="button"
              onClick={() => setOpen(true)}
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
            >
              Filters
            </button>

            <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
              {filtersConfig.map((section, sectionIdx) => (
                <Popover
                  key={section.name}
                  id={`desktop-menu-${sectionIdx}`}
                  className="relative inline-block text-left"
                >
                  <div>
                    <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      <span>{section.name}</span>
                      {filters[section.id]?.length > 0 && (
                        <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                          {filters[section.id].length}
                        </span>
                      )}
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      />
                    </Popover.Button>
                  </div>

                  <Popover.Panel
                    transition
                    className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <form className="space-y-4">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            checked={
                              filters[section.id]?.includes(option.value) ||
                              false
                            }
                            onChange={() =>
                              handleFilterChange(section.id, option.value)
                            }
                            id={`filter-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-ttBlue-600 focus:ring-ttBlue-500"
                          />
                          <label
                            htmlFor={`filter-${section.id}-${optionIdx}`}
                            className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </form>
                  </Popover.Panel>
                </Popover>
              ))}
            </Popover.Group>
          </div>
        </section>
      </div>
    </div>
  );
}
