import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import {
  getPackagePriceFromCart,
  getTotalAddOnPriceFromCart,
  getSubTotalWithDiscountFromCart,
} from "../../utils/helpers";

// Load custom font (optional)
// Font.register({ family: 'OpenSans', src: 'https://path-to-your-font/OpenSans-Regular.ttf' });

const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 20,
    paddingHorizontal: 40,
    backgroundColor: "#232d4b", // TT Navy blue
  },
  footerText: {
    color: "#7bafd4", // Light blue
    fontSize: 12,
  },
  page: {
    // flexDirection: "column",
    // backgroundColor: "#FFFFFF",
    padding: 40,
    fontFamily: "Helvetica",
    fontSize: 12,
    color: "#2d3748",
  },
  header: {
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "center",
  },
  logo: {
    height: 50,
  },
  thankYou: {
    backgroundColor: "#edf2f7",
    padding: 10,
    marginTop: 20,
    // Add more styles as needed
  },
  titleBlock: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    marginBottom: 8,
    color: "#4f576f", // TT Navy blue 400
  },
  date: {
    textAlign: "center",
    color: "#666666",
  },
  section: {
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 18,
    marginBottom: 8,
    fontFamily: "Helvetica-Bold",
    color: "#4f576f", // TT Navy blue 400
  },
  detailText: {
    marginBottom: 4,
  },
  priceText: {
    marginBottom: 4,
    fontFamily: "Helvetica-Bold",
    color: "#4f576f", // TT Navy blue 400
  },
  featureText: {
    color: "#718096",
    marginBottom: 4,
    marginLeft: 10,
  },
  billingSection: {
    marginTop: 0,
    paddingVertical: 10,
    borderTop: 1,
    borderColor: "#eeeeee",
  },
  billingTitle: {
    fontSize: 18,
    marginBottom: 8,
    fontFamily: "Helvetica-Bold",
    color: "#4f576f", // TT Navy blue 400
  },
});

const ReceiptPDF = ({ orderId, orderedCart, today }) => {
  const {
    tournament: orderedTournament,
    package: orderedPackage,
    addOns: orderedAddOns,
    team: orderedTeam,
    discount: orderedDiscount,
  } = orderedCart;

  const { subTotalWithDiscount, amountSaved } =
    getSubTotalWithDiscountFromCart(orderedCart);

  const totalAddOnPrice = getTotalAddOnPriceFromCart(orderedCart);
  const packagePrice = getPackagePriceFromCart(orderedCart);
  const subTotalPreDiscountPrice = packagePrice + totalAddOnPrice;
  const salesTaxAmount = subTotalWithDiscount * orderedTournament.localSalesTax;
  const totalOrderPrice = subTotalWithDiscount + salesTaxAmount;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image
            style={styles.logo}
            source={require("../../assets/logos/tourneyTentsLogo.png")}
          />
        </View>

        <View style={styles.titleBlock}>
          <Text style={styles.title}>Order Summary</Text>
          <Text style={styles.date}>Order #: {orderId}</Text>
          <Text style={styles.date}>{today}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionHeader}>Customer Information</Text>
          <Text style={styles.detailText}>{orderedTeam.teamName}</Text>
          <Text style={styles.detailText}>
            {orderedTeam.firstName} {orderedTeam.lastName}
          </Text>
          <Text style={styles.detailText}>{orderedTeam.email}</Text>
          <Text style={styles.detailText}>{orderedTeam.phone}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionHeader}>Tournament Details</Text>
          <Text style={styles.detailText}>{orderedTournament.name}</Text>
          <Text style={styles.detailText}>{orderedTournament.dateString}</Text>
          <Text style={styles.detailText}>
            {orderedTournament.city}, {orderedTournament.state}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionHeader}>Order Information</Text>
          <Text style={styles.detailText}>
            1. {orderedPackage.name}:{" "}
            <Text style={styles.featureText}>
              {orderedPackage.features.join(", ")}
            </Text>
          </Text>

          {orderedAddOns.map((addOn, index) => (
            <Text key={addOn.id} style={styles.detailText}>
              {index + 2}. {addOn.name} ({addOn.quantity})
            </Text>
          ))}
        </View>

        <View style={styles.billingSection}>
          <Text style={styles.billingTitle}>Billing Summary</Text>
          <Text style={styles.detailText}>
            Subtotal: $
            {subTotalPreDiscountPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          {orderedDiscount?.code && (
            <Text style={styles.detailText}>
              Discount (code: {orderedDiscount.code}): -$
              {amountSaved.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          )}
          <Text style={styles.detailText}>
            Sales Tax: $
            {salesTaxAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={styles.priceText}>
            Total: $
            {totalOrderPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </View>

        <View style={styles.thankYou}>
          <Text style={{ marginBottom: 8 }}>Thank you for your purchase!</Text>
          <Text style={{ marginBottom: 8 }}>
            We will reach out to you before your tournament begins with more
            details.
          </Text>
          <Text>
            If you have questions about your order, please contact us at{" "}
            <Text style={{ color: "#f84c1e" }}>tourneytentsmd@gmail.com</Text>.
          </Text>
        </View>

        <View style={styles.footer}>
          <Text style={styles.footerText}>tourneytentsmd@gmail.com</Text>
          <Text style={styles.footerText}>Tourney Tents LLC</Text>
          <Text style={styles.footerText}>Phone: 410-746-5385</Text>
        </View>
      </Page>
    </Document>
  );
};

export { ReceiptPDF };
