import { useEffect, useState } from "react";
import {
  dbAddOns as addOns,
  dbEventProductAvailability as eventProductAvailability,
} from "../../assets/testingData";

export default function AddOnSelection({
  setCart,
  cart,
  newStageSelectHandler,
}) {
  const [availableAddOns, setAvailableAddOns] = useState([]);

  // Update cart with new quantity
  const handleSelectAddOn = (addOn) => {
    const updatedCart = { ...cart };
    const updatedAddOns = updatedCart.addOns || [];

    // Check if the add-on is already selected
    const addOnIndex = updatedAddOns.findIndex((item) => item.id === addOn.id);

    if (addOnIndex === -1) {
      // If not selected:
      // 1) change the addOn quantity to cart.tournament.numberOfDays
      // 2) add the add-on to the cart
      addOn.quantity = cart.tournament.numberOfDays;
      updatedAddOns.push(addOn);
    } else {
      // If already selected, remove the add-on from the cart
      updatedAddOns.splice(addOnIndex, 1);
    }

    updatedCart.addOns = updatedAddOns;
    setCart(updatedCart);
  };

  // Handle continue button click
  const handleContinue = () => {
    newStageSelectHandler("Step 4", cart);
  };

  useEffect(() => {
    // Check if there are any selected add-ons in the cart
    if (cart.addOns && cart.addOns.length > 0) {
      // Create a new array with updated quantities
      const updatedAddOns = cart.addOns.map((addOn) => ({
        ...addOn,
        quantity: cart.tournament.numberOfDays, // Update quantity to match new tournament days
      }));

      // Update the cart with new add-ons
      setCart((prevCart) => ({
        ...prevCart,
        addOns: updatedAddOns,
      }));
    }
  }, [cart.tournament.numberOfDays]); // Dependency array to re-run effect when tournament days change

  useEffect(() => {
    // Get the current tournament ID
    const tournamentId = cart.tournament?.id;

    if (tournamentId) {
      // Filter add-ons based on eventProductAvailability and availability type
      const filteredAddOns = addOns.filter(
        (addOn) =>
          addOn.availability === "ALA_CARTE" && // Check if the add-on is ALA_CARTE
          eventProductAvailability.some(
            (availability) =>
              availability.eventId === tournamentId &&
              availability.productId === addOn.id &&
              availability.isAvailable
          )
      );

      // Update the available add-ons based on the selected event
      setAvailableAddOns(filteredAddOns);

    }
  }, [cart.tournament?.id]); // Run this effect when the tournament ID changes

  // console.log("AddOnSelection>cart", cart);

  return (
    <div className="mx-auto py-4">
      <div className="mx-auto max-w-7xl pb-96">
        <p className="text-gray-500 ">
          Add-ons will be delivered to your tent at the start of{" "}
          <span className="text-ttNavy-500 font-medium">
            {" "}
            each day of the tournament
          </span>
          .
        </p>
        <p className="text-sm text-gray-500">
          <sup>*</sup>prices are determined by duration of tournament
        </p>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="flex flex-wrap justify-center gap-8">
              {availableAddOns.map((addOn) => {
                // Determine if the add-on is already selected
                const isSelected =
                  cart.addOns?.some((item) => item.id === addOn.id) || false;

                return (
                  <div
                    key={addOn.id}
                    className={`flex flex-col justify-between rounded-3xl p-4 shadow-xl sm:p-6 w-[275px] ${
                      isSelected
                        ? "bg-ttBlue-200 border-2 border-ttBlue-500"
                        : "border bg-white border-gray-300"
                    }`}
                  >
                    <div className="flex flex-col h-full">
                      <div>
                        <img
                          src={addOn.imageSrc}
                          alt={addOn.name}
                          className="h-48 w-48 mx-auto"
                        />
                        <h3
                          id={addOn.id}
                          className="text-base font-semibold leading-7 text-ttNavy-600"
                        >
                          {addOn.name}
                        </h3>
                        <p className="mt-2 text-base leading-7 text-gray-600 flex-grow">
                          {addOn.description}
                        </p>
                      </div>
                      <div className="mt-auto">
                        <div className="flex items-baseline gap-x-2">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            $
                            {(
                              cart.tournament.numberOfDays * addOn.price
                            ).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 flex items-center justify-center">
                      <div
                        onClick={() => handleSelectAddOn(addOn)}
                        className={`mt-8 block rounded-md px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm ${
                          isSelected
                            ? "bg-ttNavy-500 hover:bg-ttNavy-400"
                            : "bg-ttNavy-600 hover:bg-ttNavy-500"
                        }`}
                      >
                        {isSelected
                          ? "Unselect this add-on"
                          : "Select this add-on"}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Continue Button */}
            <div className="mt-8 text-center w-1/2 md:w-1/4 lg:w-1/6 mx-auto">
              <div
                onClick={handleContinue}
                className="mt-8 block rounded-md bg-ttNavy-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-ttNavy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ttNavy-600"
              >
                Continue
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
