// import Tents from "../../assets/tents.png";
// import Tents2 from "../../assets/tents2.png";
// import Tents3 from "../../assets/tents3.png";
// import Tents4 from "../../assets/tents4.png";
// import Tents5 from "../../assets/tents5.png";
// import heroBG from "../../assets/hero_bg2.png";
import heroBG from "../../assets/hero_bg2.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TourneyTentsLogoWhite from "../../assets/logos/tourneyTentsLogoWhite.png";
import TourneyTentsLogoDarkBg from "../../assets/logos/tourneyTentsLogoDarkBackground.png";

export default function Hero({
  cart,
  reservationStages,
  setCart,
  setReservationStages,
  searchQuery,
  setSearchQuery,
}) {
  const navigate = useNavigate();

  const handleCTAButtonPress = (queryString) => {
    // Set the search query depending on the button pressed
    setSearchQuery(queryString);

    // Call handleNavigation when you want to navigate to /reservations
    navigate("/reservations");
  };

  return (
    <div className="bg-gray-900">
      <div className="relative isolate overflow-hidden">
        <img
          src={heroBG}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover"
          style={{
            filter:
              "sepia(100%) blur(2px) opacity(25%) saturate(1000%) hue-rotate(180deg)", // Example for blue tint
          }}
          onContextMenu={(e) => e.preventDefault()}
        />
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <img
              src={TourneyTentsLogoDarkBg}
              alt="Tourney Tents"
              className="mx-auto h-16 w-auto sm:h-20 sm:w-auto"
            />
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Premier tournament concierge service
            </h1>
            <div className="mt-6 text-lg leading-8 text-gray-300">
              Tournaments should be a SPECTATOR sport and none of the hassle.
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link to="/reservations" offset={0} duration={500}>
                <button
                  type="button"
                  className="rounded-md bg-transparent outline-none ring-2 ring-ttOrange-400 focus:outline-none focus:ring-2 focus:ring-ttOrange-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-25 hover:bg-ttOrange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ttOrange-400"
                >
                  BOOK NOW
                </button>
              </Link>
              {/* <button
                onClick={() =>
                  handleCTAButtonPress("Mid-Atlantic Summer Club Championship")
                }
                className="rounded-md bg-transparent outline-none ring-2 ring-ttOrange-400 focus:outline-none focus:ring-2 focus:ring-ttOrange-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-25 hover:bg-ttOrange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ttOrange-400"
              >
                Book Mid-Atlantic Summer Club Championship
              </button> */}
              {/* <button
                onClick={() => handleCTAButtonPress("NHSLS")}
                className="rounded-md bg-transparent outline-none ring-2 ring-ttBlue-400 focus:outline-none focus:ring-2 focus:ring-ttBlue-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-25 hover:bg-ttBlue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ttBlue-400"
              >
                Book NHSLS
              </button> */}
            </div>
            {/* <div className="mt-4">
              <Link
                to="/Contact"
                offset={0}
                duration={500}
                className="inline-block"
              >
                <button
                  type="button"
                  className="inline text-sm font-semibold leading-6 text-ttBlue-500"
                >
                  Let us know about an upcoming tournament
                  <span aria-hidden="true">→</span>
                </button>
              </Link>
            </div> */}
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
