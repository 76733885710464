/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      subDescription
      features
      href
      imageSrc
      price
      events {
        nextToken
        __typename
      }
      orderItems {
        nextToken
        __typename
      }
      availability
      stillOffered
      discounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $id: ID
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      city
      state
      dateString
      numberOfDays
      sport
      gender
      link
      imageAlt
      imageSrc
      startTimestamp
      closeRegistrationTimestamp
      singlePrice
      doublePrice
      localSalesTax
      products {
        nextToken
        __typename
      }
      orders {
        nextToken
        __typename
      }
      discounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $id: ID
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventProductAvailability = /* GraphQL */ `
  query GetEventProductAvailability($id: ID!) {
    getEventProductAvailability(id: $id) {
      id
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventProductAvailabilities = /* GraphQL */ `
  query ListEventProductAvailabilities(
    $id: ID
    $filter: ModelEventProductAvailabilityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventProductAvailabilities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        eventId
        productId
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      teamName
      tournamentName
      tournamentCity
      tournamentDate
      teamDivision
      teamYear
      teamColor
      firstName
      lastName
      email
      phone
      preferredContactMethod
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
      orders {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $id: ID
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        teamName
        tournamentName
        tournamentCity
        tournamentDate
        teamDivision
        teamYear
        teamColor
        firstName
        lastName
        email
        phone
        preferredContactMethod
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!, $dateOfOrder: AWSDateTime!) {
    getOrder(id: $id, dateOfOrder: $dateOfOrder) {
      id
      customerId
      customer {
        id
        teamName
        tournamentName
        tournamentCity
        tournamentDate
        teamDivision
        teamYear
        teamColor
        firstName
        lastName
        email
        phone
        preferredContactMethod
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      stripePaymentIntentId
      stripePaymentStatus
      stripeCustomerId
      dateOfOrder
      totalPrice
      salesTax
      discountId
      discount {
        id
        code
        description
        type
        value
        expirationDate
        usageLimit
        createdAt
        updatedAt
        productId
        eventId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $id: ID
    $dateOfOrder: ModelStringKeyConditionInput
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      id: $id
      dateOfOrder: $dateOfOrder
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        customerId
        eventId
        stripePaymentIntentId
        stripePaymentStatus
        stripeCustomerId
        dateOfOrder
        totalPrice
        salesTax
        discountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrderItem = /* GraphQL */ `
  query GetOrderItem($id: ID!) {
    getOrderItem(id: $id) {
      id
      orderId
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      quantity
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $id: ID
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        orderId
        productId
        quantity
        price
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      id
      code
      description
      type
      value
      expirationDate
      usageLimit
      createdAt
      updatedAt
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      orders {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts(
    $id: ID
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDiscounts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        description
        type
        value
        expirationDate
        usageLimit
        createdAt
        updatedAt
        productId
        eventId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByStartTimestamp = /* GraphQL */ `
  query EventsByStartTimestamp(
    $startTimestamp: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByStartTimestamp(
      startTimestamp: $startTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventProductAvailabilitiesByEventId = /* GraphQL */ `
  query EventProductAvailabilitiesByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventProductAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventProductAvailabilitiesByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        productId
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventProductAvailabilitiesByProductId = /* GraphQL */ `
  query EventProductAvailabilitiesByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventProductAvailabilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventProductAvailabilitiesByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        productId
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customersByTeamName = /* GraphQL */ `
  query CustomersByTeamName(
    $teamName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByTeamName(
      teamName: $teamName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamName
        tournamentName
        tournamentCity
        tournamentDate
        teamDivision
        teamYear
        teamColor
        firstName
        lastName
        email
        phone
        preferredContactMethod
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customersByEmail = /* GraphQL */ `
  query CustomersByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamName
        tournamentName
        tournamentCity
        tournamentDate
        teamDivision
        teamYear
        teamColor
        firstName
        lastName
        email
        phone
        preferredContactMethod
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByCustomerId = /* GraphQL */ `
  query OrdersByCustomerId(
    $customerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        eventId
        stripePaymentIntentId
        stripePaymentStatus
        stripeCustomerId
        dateOfOrder
        totalPrice
        salesTax
        discountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByEventId = /* GraphQL */ `
  query OrdersByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        eventId
        stripePaymentIntentId
        stripePaymentStatus
        stripeCustomerId
        dateOfOrder
        totalPrice
        salesTax
        discountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByDiscountId = /* GraphQL */ `
  query OrdersByDiscountId(
    $discountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByDiscountId(
      discountId: $discountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        eventId
        stripePaymentIntentId
        stripePaymentStatus
        stripeCustomerId
        dateOfOrder
        totalPrice
        salesTax
        discountId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemsByOrderId = /* GraphQL */ `
  query OrderItemsByOrderId(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        productId
        quantity
        price
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderItemsByProductId = /* GraphQL */ `
  query OrderItemsByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderItemsByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        productId
        quantity
        price
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const discountByCode = /* GraphQL */ `
  query DiscountByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        description
        type
        value
        expirationDate
        usageLimit
        createdAt
        updatedAt
        productId
        eventId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const discountsByProductId = /* GraphQL */ `
  query DiscountsByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountsByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        description
        type
        value
        expirationDate
        usageLimit
        createdAt
        updatedAt
        productId
        eventId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const discountsByEventId = /* GraphQL */ `
  query DiscountsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        description
        type
        value
        expirationDate
        usageLimit
        createdAt
        updatedAt
        productId
        eventId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      email
      firstName
      lastName
      imageSrc
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $id: ID
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdmins(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        firstName
        lastName
        imageSrc
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const adminsByEmail = /* GraphQL */ `
  query AdminsByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        imageSrc
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
