import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { dbDiscounts } from "../../assets/testingData";

export default function Banner() {
  const [showBanner, setShowBanner] = useState(true);
  const [bannerText, setBannerText] = useState("");
  const [earliestExpirationDiscount, setEarliestExpirationDiscount] =
    useState(null);
  // const [weeksAwayFromNextEvent, setWeeksAwayFromNextEvent] = useState(0);

  const handleDismissBanner = () => {
    setShowBanner(false);
  };

  useEffect(() => {
    // Find the discount with the next expiration date, after 10/16/2024
    const nextExpiringDiscount = dbDiscounts.reduce(
      (earliestDiscount, discount) => {
        const expirationDate = new Date(discount.expirationDate);
        // const comparisonDate = new Date("2024-09-18");
        const comparisonDate = new Date(); // Current date

        // Check if the current discount's expiration date is valid and after the comparison date
        if (expirationDate > comparisonDate) {
          // If there's no earliest discount yet or if the current discount's expiration date is earlier than the existing one
          if (
            !earliestDiscount ||
            expirationDate < new Date(earliestDiscount.expirationDate)
          ) {
            return discount;
          }
        }
        return earliestDiscount;
      },
      null
    );

    // console.log("nextExpiringDiscount: ", nextExpiringDiscount);
    if (!nextExpiringDiscount) {
      setEarliestExpirationDiscount(null);
      setShowBanner(false);
    } else {
      // Set banner text to the description of the discount with the earliest expiration date
      setEarliestExpirationDiscount(nextExpiringDiscount);
      setBannerText(nextExpiringDiscount.description);
      setShowBanner(true);
    }
  }, []);

  if (showBanner && earliestExpirationDiscount) {
    return (
      <div className="flex items-center gap-x-6  bg-ttOrange-500 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <p className="text-sm leading-6 text-white">
          {earliestExpirationDiscount.description}
          {/* <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg> */}
          <span className="ml-2">
            <Link to="/reservations">
              <strong className="font-semibold">
                Use code {earliestExpirationDiscount.code} at checkout!
              </strong>
            </Link>
          </span>
        </p>
        <div className="flex flex-1 justify-end">
          <button
            type="button"
            onClick={handleDismissBanner}
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
