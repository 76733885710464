import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as ChecklistIcon } from "../../assets/icons/checklist.svg";
import { ReactComponent as FAQIcon } from "../../assets/icons/faq.svg";
import { ReactComponent as ReviewsIcon } from "../../assets/icons/reviews.svg";
import { ReactComponent as ProcessIcon } from "../../assets/icons/process.svg";
import { ReactComponent as CanopyIcon } from "../../assets/icons/canopy_black.svg";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

import TourneyTentsLogoWithTagline from "../../assets/logos/tourneyTentsLogoWithTagline.png";
import FindTourneyCombobox from "./FindTourneyCombobox";

const products = [
  {
    name: "Packages",
    description: "Learn about what we offer",
    href: "/#packages",
    icon: CanopyIcon,
  },
  {
    name: "Process",
    description: "Quick overview of how it works",
    href: "/#process",
    icon: ProcessIcon,
  },
  // {
  //   name: "Reviews",
  //   description: "Hear from our happy customers",
  //   href: "/#reviews",
  //   icon: ReviewsIcon,
  // },
  {
    name: "FAQS",
    description: "Get your questions answered",
    href: "/#faq",
    icon: FAQIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TopNav({
  cart,
  setCart,
  reservationStages,
  setReservationStages,
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  // if you click on logo from /root, it should scroll to top. If you click on it from anywhere else, it shoudl go to root /.
  const handleLogoClick = () => {
    if (location.pathname === "/") {
      scrollToTop();
    } else {
      window.location.href = "/";
    }
  };

  const scrollToTop = () => {
    mobileMenuOpen && setMobileMenuOpen(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // if you want a smooth scrolling effect
    });
    window.location.hash = ""; // reset the hash
  };

  return (
    <header id="top" className="bg-white sticky top-0 z-20">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex mr-8 -m-1.5 p-1.5" onClick={handleLogoClick}>
          <span className="sr-only">Tourney Tents</span>
          <img
            className="h-10 w-auto"
            src={TourneyTentsLogoWithTagline}
            alt=""
          />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-10">
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              Product
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-ttNavy-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <Link
                          to={item.href}
                          className="block font-semibold text-gray-900"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <Link
            to="reservations"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => setMobileMenuOpen(false)}
          >
            Reserve Your Tents!
          </Link>
          <Link
            to="contact"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => setMobileMenuOpen(false)}
          >
            Contact Us
          </Link>
        </Popover.Group>
        {/* Don't show combobox if on reservations screen */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <div className="w-full max-w-lg lg:max-w-xs items-center">
            {location.pathname !== "/reservations" && (
              <>
                <label htmlFor="search" className="sr-only">
                  Search Upcoming Events
                </label>
                <FindTourneyCombobox
                  cart={cart}
                  reservationStages={reservationStages}
                  setCart={setCart}
                  setReservationStages={setReservationStages}
                  setMobileMenuOpen={setMobileMenuOpen}
                />
              </>
            )}
          </div>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <div className="-m-1.5 p-1.5" onClick={handleLogoClick}>
              <span className="sr-only">Tourney Tents</span>
              <img
                className="h-16 w-auto"
                src={TourneyTentsLogoWithTagline}
                alt=""
              />
            </div>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Product
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {products.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as={Link}
                            to={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link
                  to="reservations"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Reserve Your Tents
                </Link>
                <Link
                  to="contact"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact Us
                </Link>
              </div>
              <div className="py-6">
                <div className="w-full max-w-lg lg:max-w-sm">
                  {location.pathname !== "/reservations" && (
                    <>
                      <label htmlFor="search" className="sr-only">
                        Search Upcoming Events
                      </label>
                      <FindTourneyCombobox
                        cart={cart}
                        reservationStages={reservationStages}
                        setCart={setCart}
                        setReservationStages={setReservationStages}
                        setMobileMenuOpen={setMobileMenuOpen}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
