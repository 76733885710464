import { useState, useEffect } from "react";
import { useLocation, useNavigate, redirect } from "react-router-dom";
import { RotateLoader } from "react-spinners";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReceiptPDF } from "../components/orderSummary/ReceiptPDF";
import {
  getPackagePriceFromCart,
  getTotalAddOnPriceFromCart,
  getSubTotalWithDiscountFromCart,
} from "../utils/helpers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const today = new Date().toLocaleDateString();

export default function OrderSummary() {
  // Get cart from navigate
  const location = useLocation();
  const navigate = useNavigate();

  // Put this in a useEffect to redirect to Reservations if no cart
  useEffect(() => {
    if (!location.state || !location.state.orderedCart) {
      setTimeout(() => {
        navigate("/Reservations");
      }, 5000);
    }
  }, [location, navigate]);

  // If no cart, show loading spinner before redirect
  if (!location.state || !location.state.orderedCart) {
    return (
      <div
        className="flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 92px - 128px)" }}
      >
        <RotateLoader color={"#f84c1e"} />
        <h2 className="mt-8 text-lg text-ttOrange-500">
          {" "}
          You need to place an order in order to have an order summary!{" "}
        </h2>
        <p className="text-gray-500">Redirecting you now.</p>
      </div>
    );
  }
  const orderedCart = location.state.orderedCart;
  const {
    tournament: orderedTournament,
    package: orderedPackage,
    addOns: orderedAddOns,
    team: orderedTeam,
    discount: orderedDiscount,
  } = orderedCart;

  const { subTotalWithDiscount, amountSaved } =
    getSubTotalWithDiscountFromCart(orderedCart);

  const totalAddOnPrice = getTotalAddOnPriceFromCart(orderedCart);
  const packagePrice = getPackagePriceFromCart(orderedCart);
  const subTotalPreDiscountPrice = packagePrice + totalAddOnPrice;
  const salesTaxAmount = subTotalWithDiscount * orderedTournament.localSalesTax;
  const totalOrderPrice = subTotalWithDiscount + salesTaxAmount;

  const orderId = location.state.orderId;

  // console.log("OrderSummary>orderedTournament: ", orderedTournament);
  // console.log("OrderSummary>orderedPackage: ", orderedPackage);
  // console.log("OrderSummary>orderedAddOns: ", orderedAddOns);
  // console.log("OrderSummary>orderedTeam: ", orderedTeam);

  return (
    <div className="bg-white" id="order-summary">
      <div className="mx-auto max-w-2xl pt-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
          <div className="flex sm:items-baseline sm:space-x-4">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Order #: {orderId}
            </h1>
            <PDFDownloadLink
              document={
                <ReceiptPDF
                  orderId={orderId}
                  orderedCart={orderedCart}
                  today={today}
                />
              }
              fileName="TT-OrderSummary.pdf"
              className="text-sm font-medium text-ttOrange-600 hover:text-ttOrange-500 sm:block"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download Order Summary"
              }
            </PDFDownloadLink>
          </div>
          <p className="text-sm text-gray-600">
            Order placed{" "}
            <time dateTime="2021-03-22" className="font-medium text-gray-900">
              {today}
            </time>
          </p>
        </div>

        {/* Products */}
        <div className="mt-6">
          <h2 className="sr-only">Products purchased</h2>

          <div className="space-y-8">
            <div
              key={orderedPackage.id}
              className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
            >
              <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                <div className="sm:flex lg:col-span-7">
                  <div className="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                    <img
                      src={orderedTournament.imageSrc}
                      alt={orderedTournament.name}
                      className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                    />
                  </div>

                  <div className="mt-6 sm:ml-6 sm:mt-0">
                    <h3 className="text-base font-medium text-gray-900">
                      {orderedTournament.name}
                    </h3>
                    <p className=" text-sm text-gray-500">
                      {orderedTournament.city}, {orderedTournament.state}
                    </p>
                    <p className="text-sm text-gray-500">
                      {orderedTournament.dateString}
                    </p>
                    <p className="mt-2 text-sm font-medium text-gray-900">
                      {orderedPackage.name}
                      <span className="ml-2 text-sm text-gray-500">
                        $
                        {packagePrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                    {orderedAddOns.map((addOn) => (
                      <p key={addOn.id} className="ml-2 text-sm text-gray-900">
                        {addOn.name}({addOn.quantity})
                        <span className="ml-2 text-sm text-gray-500">
                          $
                          {(addOn.price * addOn.quantity).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </span>
                      </p>
                    ))}
                  </div>
                </div>

                <div className="mt-6 lg:col-span-5 lg:mt-0">
                  <dl className="text-sm">
                    <div>
                      <dt className="font-medium text-gray-900">
                        Order contact
                      </dt>
                      <dd className="mt-3 text-gray-500">
                        <span className="block text-ttNavy-400 font-medium">
                          {orderedTeam.teamName}
                        </span>
                        <span className="block">
                          {orderedTeam.firstName} {orderedTeam.lastName}
                        </span>
                        <span className="block">
                          {orderedTeam.email}{" "}
                          {orderedTeam.preferredContactMethod === "email"
                            ? "(preferred)"
                            : ""}
                        </span>
                        <span className="block">
                          {orderedTeam.phone}{" "}
                          {orderedTeam.preferredContactMethod === "phone"
                            ? "(preferred)"
                            : ""}
                        </span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Billing */}
        <div className="mt-16">
          <h2 className="sr-only">Billing Summary</h2>

          <div className="bg-gray-100 px-4 py-6 sm:rounded-lg sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-8">
            <dl className="grid gap-6 text-sm md:gap-x-8 lg:col-span-7">
              <div>
                <h2 className="font-bold text-lg text-ttNavy-400">
                  Thank you for your purchase!
                </h2>
              </div>
              <div>
                <p className="font-medium text-gray-600">
                  A receipt was mailed to:{" "}
                  <span className="text-ttBlue-500">{orderedTeam.email}</span>.
                </p>
                <p className="font-medium text-gray-600">
                  If you have questions about your order, please contact us at{" "}
                  <a
                    className="text-ttOrange-500"
                    href="mailto:tourneytentsmd@gmail.com"
                  >
                    tourneytentsmd@gmail.com
                  </a>
                  .
                </p>
              </div>
            </dl>

            <dl className="mt-8 divide-y divide-gray-200 text-sm lg:col-span-5 lg:mt-0">
              <div className="flex items-center justify-between pb-4">
                <dt className="text-gray-600">Subtotal</dt>
                <dd className="font-medium text-gray-900">
                  $
                  {subTotalPreDiscountPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </dd>
              </div>
              {orderedDiscount?.id && (
                <div className="flex items-center justify-between py-4">
                  <dt className="text-gray-600">
                    Discount{" "}
                    <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                      {orderedDiscount.code}
                    </span>
                  </dt>
                  <dd className="font-medium text-gray-900">
                    -$
                    {amountSaved.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </dd>
                </div>
              )}

              <div className="flex items-center justify-between py-4">
                <dt className="text-gray-600">Tax</dt>
                <dd className="font-medium text-gray-900">
                  $
                  {salesTaxAmount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </dd>
              </div>
              <div className="flex items-center justify-between pt-4">
                <dt className="font-medium text-gray-900">Order total</dt>
                <dd className="font-medium text-ttNavy-600">
                  $
                  {totalOrderPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
