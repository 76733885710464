import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { initGA, logPageView } from "./utils/analytics";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Reservations from "./pages/Reservations";
import TopNav from "./components/shared/TopNav";
import Footer from "./components/shared/Footer";
import OrderSummary from "./pages/OrderSummary";
import NotFoundPage from "./pages/NotFoundPage";
import RouteChangeListener from "./utils/RouteChangeListener";

import { Amplify } from "aws-amplify";
import config from './aws-exports'; // or './amplifyconfiguration.json'

Amplify.configure(config);

const initialReservationStages = [
  {
    id: "Step 1",
    name: "Select your tournament",
    status: "current",
    active: true,
  },
  {
    id: "Step 2",
    name: "Choose your package",
    status: "upcoming",
    active: false,
  },
  {
    id: "Step 3",
    name: "Select add-ons",
    status: "upcoming",
    active: false,
  },
  {
    id: "Step 4",
    name: "Enter team details",
    status: "upcoming",
    active: false,
  },
  {
    id: "Step 5",
    name: "Checkout",
    status: "upcoming",
    active: false,
  },
];

function App() {
  const [cart, setCart] = useState({ tournament: {}, package: {}, addOns: [], team: {}, discount: {} });
  const [searchQuery, setSearchQuery] = useState("");

  // Copy the initialReservationStages array and set it to reservationStages
  const [reservationStages, setReservationStages] = useState(
    initialReservationStages.map((stage) => ({ ...stage }))
  );

  useEffect(() => {
    initGA();
    // If using React Router, you might want to listen to route changes
    // and log those page views as well.
  }, []);

  return (
    <Router>
      <RouteChangeListener /> {/* Listen for route changes */}
      <TopNav
        cart={cart}
        reservationStages={reservationStages}
        setCart={setCart}
        setReservationStages={setReservationStages}
      />
      <main>
        <Routes>
          <Route
            path="/"
            sensitive={false}
            element={
              <Home
                cart={cart}
                reservationStages={reservationStages}
                setCart={setCart}
                setReservationStages={setReservationStages}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            }
          />
          <Route
            path="/Home"
            sensitive={false}
            element={
              <Home
                cart={cart}
                reservationStages={reservationStages}
                setCart={setCart}
                setReservationStages={setReservationStages}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            }
          />
          <Route
            path="/Reservations"
            sensitive={false}
            element={
              <Reservations
                cart={cart}
                reservationStages={reservationStages}
                setCart={setCart}
                setReservationStages={setReservationStages}
                initialReservationStages={initialReservationStages}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            }
          />
          <Route
            path="/OrderSummary"
            sensitive={false}
            element={
              <OrderSummary
                setCart={setCart}
                reservationStages={reservationStages}
                setReservationStages={setReservationStages}
                initialReservationStages={initialReservationStages}
                // searchQuery={searchQuery}
                // setSearchQuery={setSearchQuery}
              />
            }
          />
          <Route path="/Contact" sensitive={false} element={<Contact />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
