import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { ReactComponent as MarylandIcon } from "../../assets/icons/maryland.svg";
import { sendContactUsEmail } from "../../api/apiCalls";
import Notification from "./Notification";

export default function ContactBody() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [show, setShow] = useState(false);
  const [notificationBody, setNotificationBody] = useState({
    title: "",
    description: "",
    type: "",
  });

  const validationSchema = Yup.object().shape({
    // Assuming your form fields are "name" and "email" for demonstration
    name: Yup.string().required("Your name is required"),
    webMessage: Yup.string().required("A message is required."),
    email: Yup.string()
      .required("An email is required")
      .test(
        "is-valid",
        "Email must be in the format: yourname@example.com",
        (value) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value);
        }
      ),
    phone: Yup.string().matches(
      /^[0-9]{10}$/,
      "Please enter a 10-digit number, with no hyphens or spaces."
    ),
  });

  // Custom ErrorMessage component with Tailwind CSS styling
  const FormikError = ({ name }) => (
    <ErrorMessage
      name={name}
      component="div"
      className="text-ttOrange-500 text-sm mt-1" // Tailwind classes
    />
  );

  const contactSubmitClickHandler = async (values) => {
    // TODO unpack values
    const { name, email, phone, webMessage } = values;

    const contactUsEmailResponse = await sendContactUsEmail(
      name,
      email,
      phone,
      webMessage
    );

    // console.log("contactUsEmailResponse: ", contactUsEmailResponse);
    if (contactUsEmailResponse.success) {
      setNotificationBody({
        title: "Success!",
        description:
          "Your message has been sent successfully. We will respond to you as soon as possible.",
        type: "success",
      });
      setShow(true);
    } else {
      setNotificationBody({
        title: "Error!",
        description:
          "There was an issue sending your message. Please try again later.",
        type: "fail",
      });
      setShow(true);
    }
  };

  return (
    <>
      <div className="relative bg-white">
        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
          <div className="bg-white px-6 py-16 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-lg">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Get in touch
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
              Are you travelling to the mid-Atlantic for a tournament? Or hosting a tournament and would like to offer our services? Let us know about your tournament needs by filling out the contact form.
              </p>
              <p className="mt-3 text-lg leading-6 text-gray-500">
              Reach out to us if you have any questions, need help with your
                reservation, or are looking to partner with us at your next
                event.
              </p>
              <dl className="mt-8 text-base text-gray-500">
                <div>
                  <dt className="sr-only">Location</dt>
                  <dd className="flex">
                    <MarylandIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">Proudly based in Baltimore, MD</span>
                  </dd>
                </div>
                {/* <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+1 (555) 123-4567</span>
                  </dd>
                </div> */}
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <EnvelopeIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">tourneytentsmd@gmail.com</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white px-6 py-16 lg:col-span-3 lg:px-8 lg:py-24 xl:pl-12">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  webMessage: "",
                }}
                validationSchema={validationSchema}
                validateOnBlur={true}
              >
                {({ values, isValid }) => (
                  <Form className="grid grid-cols-1 gap-y-6">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Name<sup>*</sup>
                      </label>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500"
                      />
                      <FormikError name="name" component="div" />
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email<sup>*</sup>
                      </label>
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500"
                      />
                      <FormikError name="email" component="div" />
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone
                      </label>
                      <Field
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500"
                      />
                      <FormikError name="phone" component="div" />
                    </div>
                    <div>
                      <label
                        htmlFor="webMessage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Message<sup>*</sup>
                      </label>
                      <Field
                        as="textarea"
                        id="webMessage"
                        name="webMessage"
                        rows={4}
                        className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-ttBlue-500 focus:ring-ttBlue-500"
                      />
                      <FormikError name="webMessage" component="div" />
                    </div>
                    <ReCAPTCHA
                      sitekey="6Ld_i3spAAAAACMtdkaK6UYdvu-OCJGrsLEoP2NL"
                      onChange={setRecaptchaValue}
                    />
                    <div>
                      <button
                        type="button"
                        // className="inline-flex justify-center rounded-md border border-transparent bg-ttNavy-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-ttBlue-700 focus:outline-none focus:ring-2 focus:ring-ttBlue-500 focus:ring-offset-2"
                        className={`inline-flex justify-center rounded-md border px-6 py-3 text-base font-medium shadow-sm ${
                          isValid && recaptchaValue && !isSubmitting
                            ? "border-transparent bg-ttNavy-600 text-white hover:bg-ttNavy-700 focus:ring-ttNavy-500"
                            : "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed"
                        }`}
                        disabled={!isValid || isSubmitting || !recaptchaValue}
                        onClick={() => {
                          setIsSubmitting(true);
                          contactSubmitClickHandler(values);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Notification
        title={notificationBody.title}
        description={notificationBody.description}
        type={notificationBody.type}
        show={show}
        setShow={setShow}
      />
    </>
  );
}
