export default function SingleTestimonial({ image, quote, name, affiliation }) {
    return (
      <div className="bg-white my-8 mx-4">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative overflow-hidden bg-white px-4 py-6 sm:rounded-3xl sm:px-4 sm:py-6 md:px-3 lg:px-4">
            <div className="relative mx-auto max-w-2xl lg:mx-0">
              <figure>
                <blockquote className="text-lg font-semibold text-gray-500 sm:text-xl sm:leading-8">
                  <p>{quote}</p>
                </blockquote>
                <div className="mt-6 flex items-center">
                  <img alt="" src={image} className="w-36 mr-4" />
                  <figcaption className="text-base text-gray-500">
                    <div className="font-semibold">{name}</div>
                    <div className="mt-1">{affiliation}</div>
                  </figcaption>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    );
  }
  