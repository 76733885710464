import React, { useState, useEffect } from "react";
import SimpleStepper from "../components/reservations/SimpleStepper";
import PackageSelection from "../components/reservations/PackageSelection";
import AddOnSelection from "../components/reservations/AddOnSelection";
import Banner from "../components/landing/Banner";
import BulletStepper from "../components/reservations/BulletStepper";
import TournamentCardList from "../components/reservations/TournamentCardList";
import {
  dbTournaments,
  dbAddOns as addOns,
  dbEventProductAvailability as eventProductAvailability,
} from "../assets/testingData";
import {
  filterTournamentsBySearch,
  filterTournamentsByCriteria,
  sortTournaments,
} from "../utils/helpers";
import SearchBar from "../components/reservations/SearchBar";
import { Check } from "@mui/icons-material";
import TeamDetails from "../components/reservations/TeamDetails";
import Payments from "../components/reservations/Payments";
import FilterSortBar from "../components/reservations/FilterSortBar";
import { createPaymentIntent } from "../api/apiCalls";

export default function Reservations({
  cart,
  setCart,
  reservationStages,
  setReservationStages,
  initialReservationStages,
  searchQuery,
  setSearchQuery,
}) {
  const [shouldWiggle, setShouldWiggle] = useState(false);

  const [filters, setFilters] = useState({
    sport: [],
    gender: [],
    state: [],
  });
  const [sortBy, setSortBy] = useState("date"); // Default sort option
  const [sortDirection, setSortDirection] = useState("asc"); // Default sort direction
  const [sortedAndFilteredTournaments, setSortedAndFilteredTournaments] =
    useState([]);

  const [clientSecret, setClientSecret] = useState(null);
  const [isFetchingPaymentIntent, setIsFetchingPaymentIntent] = useState(false);

  const activeStep = reservationStages.find((step) => step.active === true);
  const activeStepName = activeStep ? activeStep.name : "";

  function newStageSelectHandler(id, newCart) {
    // console.log("newStageSelectHandler>newCart", newCart);
    // Copy the reservationStages array
    let updatedStages = [...reservationStages];

    // Find the stage with the given id
    let stage = updatedStages.find((stage) => stage.id === id);

    // Check the conditions for each stage
    if (stage.id === "Step 2" && Object.keys(newCart.tournament).length === 0) {
      // Don't allow the user to go to Step 2 if cart.tournament is an empty object
      setShouldWiggle(true);
      return;
    }

    // Check the conditions for each stage
    if (
      stage.id === "Step 3" &&
      (Object.keys(newCart.package).length === 0 ||
        Object.keys(newCart.tournament).length === 0)
    ) {
      // Don't allow the user to go to Step 2 if cart.package is an empty object
      setShouldWiggle(true);
      return;
    }

    // You can go to Step 4 even if there are no add-ons selected

    if (
      stage.id === "Step 4" &&
      (Object.keys(newCart.package).length === 0 ||
        Object.keys(newCart.tournament).length === 0)
    ) {
      // Don't allow the user to go to Step 3 if cart.package or cart.tournament is an empty object
      setShouldWiggle(true);
      return;
    }
    // console.log(newCart);
    if (
      stage.id === "Step 5" &&
      (Object.keys(newCart.package).length === 0 ||
        Object.keys(newCart.tournament).length === 0 ||
        Object.keys(newCart.team).length !== 18 || // There are 18 fields in the team object
        Object.entries(newCart.team).some(([key, value]) => {
          if (["addressLine2", "teamColor"].includes(key)) {
            return false; // always allow addressLine2 or teamColor to be ""
          } else if (
            ["teamDivision", "teamYear"].includes(key) &&
            (newCart.tournament.id === "cFtqjRmLLP7" ||
              newCart.tournament.id === "TMDF9hb6pmJ")
          ) {
            return false; // only allow these fields to be "" if tournamentId is "XX" or "YY"
          } else if (
            ["tournamentName", "tournamentCity", "tournamentDate"].includes(
              key
            ) &&
            newCart.tournament.id !== "FtTTnv9Umpm"
          ) {
            return false; // only allow these fields to be "" if tournamentId is not "YY"
          } else {
            return value === ""; // for all other fields, check if the value is ""
          }
        }))
    ) {
      // Don't allow the user to go to Step 3 if cart.package or cart.tournament is an empty object
      setShouldWiggle(true);
      return;
    }

    // If the conditions are met, update the active stage
    updatedStages.forEach((stage) => {
      if (stage.id === id) {
        stage.status = "current";
        stage.active = true;
      } else {
        stage.status = "complete";
        stage.active = false;
      }
    });
    setReservationStages(updatedStages);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  // Function to apply all filters
  const applyFiltersAndSort = () => {
    // Filter based on search query
    let filteredTourneys = filterTournamentsBySearch(
      searchQuery,
      dbTournaments
    );

    // Apply additional filters
    filteredTourneys = filterTournamentsByCriteria(filteredTourneys, filters);

    // Sort tournaments
    const sortedFilteredTourneys = sortTournaments(
      filteredTourneys,
      sortBy,
      sortDirection
    );

    setSortedAndFilteredTournaments(sortedFilteredTourneys);
  };

  // useEffect to apply filtering and sorting whenever dependencies change
  useEffect(() => {
    applyFiltersAndSort();
  }, [sortBy, sortDirection, searchQuery, filters]); // Add filters as dependency

  useEffect(() => {
    // Run whenever the cart's tournament changes
    const tournamentId = cart.tournament?.id;
    if (tournamentId) {
      // Check the current add-ons in the cart against the event product availability
      const availableAddOns = addOns.filter((addOn) =>
        eventProductAvailability.some(
          (availability) =>
            availability.eventId === tournamentId &&
            availability.productId === addOn.id &&
            availability.isAvailable
        )
      );

      // Update the cart to remove any add-ons not available for the selected tournament
      const filteredAddOns = cart.addOns.filter((addOn) =>
        availableAddOns.some((availableAddOn) => availableAddOn.id === addOn.id)
      );

      // If the filtered add-ons are different from the current cart add-ons, update the cart
      if (filteredAddOns.length !== cart.addOns.length) {
        setCart((prevCart) => ({ ...prevCart, addOns: filteredAddOns }));
      }
    }
  }, [cart.tournament]);

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      // console.log("cart", cart);

      if (!isFetchingPaymentIntent && cart.package.id) {
        // Create PaymentIntent as soon as the page loads

        const paymentIntent = await createPaymentIntent(cart);
        // const paymentIntent = await createPaymentIntent(
        //   cart.team.email,
        //   cart.tournament.state,
        //   receiptDescriptionText,
        //   [cart.package.id]
        // );
        setClientSecret(paymentIntent.clientSecret);
        setIsFetchingPaymentIntent(false);
      }
    };

    fetchPaymentIntent();
  }, [
    cart.package,
    JSON.stringify(cart.addOns), // Track changes in addOns content
    cart.discount,
    cart.tournament,
    isFetchingPaymentIntent,
  ]);

  return (
    <>
      <div className="bg-white py-4 sm:py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
          {/* Begin: Desktop stepper */}
          <div className="hidden md:block">
            <SimpleStepper
              reservationStages={reservationStages}
              setReservationStages={setReservationStages}
              activeStep={activeStep}
              activeStepName={activeStepName}
              newStageSelectHandler={newStageSelectHandler}
              setShouldWiggle={setShouldWiggle}
              shouldWiggle={shouldWiggle}
              cart={cart}
            />
          </div>
          {/* End: Desktop stepper */}
          {/* Begin: Mobile stepper */}
          <div className="md:hidden">
            <BulletStepper
              reservationStages={reservationStages}
              setReservationStages={setReservationStages}
              activeStep={activeStep}
              activeStepName={activeStepName}
              newStageSelectHandler={newStageSelectHandler}
              setShouldWiggle={setShouldWiggle}
              shouldWiggle={shouldWiggle}
              cart={cart}
            />
          </div>
          {/* End: Mobile stepper */}
          {/* Stage 1 */}
          {reservationStages.find(
            (stage) => stage.id === "Step 1" && stage.active === true
          ) && (
            <>
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                sortBy={sortBy}
                setSortBy={setSortBy}
              />

              <FilterSortBar
                filters={filters}
                setFilters={setFilters}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
              />

              <TournamentCardList
                sortedAndFilteredTournaments={sortedAndFilteredTournaments}
                cart={cart}
                setCart={setCart}
                newStageSelectHandler={newStageSelectHandler}
              />
            </>
          )}
          {/* Stage 2 */}
          {reservationStages.find(
            (stage) => stage.id === "Step 2" && stage.active === true
          ) && (
            <PackageSelection
              cart={cart}
              setCart={setCart}
              newStageSelectHandler={newStageSelectHandler}
            />
          )}
          {/* Stage 3 */}
          {reservationStages.find(
            (stage) => stage.id === "Step 3" && stage.active === true
          ) && (
            <AddOnSelection
              cart={cart}
              setCart={setCart}
              newStageSelectHandler={newStageSelectHandler}
            />
          )}
          {/* Stage 4 */}
          {reservationStages.find(
            (stage) => stage.id === "Step 4" && stage.active === true
          ) && (
            <TeamDetails
              cart={cart}
              setCart={setCart}
              reservationStages={reservationStages}
              setReservationStages={setReservationStages}
              initialReservationStages={initialReservationStages}
              newStageSelectHandler={newStageSelectHandler}
            />
          )}
          {/* Stage 5 */}
          {reservationStages.find(
            (stage) => stage.id === "Step 5" && stage.active === true
          ) && (
            <Payments
              cart={cart}
              setCart={setCart}
              reservationStages={reservationStages}
              setReservationStages={setReservationStages}
              initialReservationStages={initialReservationStages}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              clientSecret={clientSecret}
            />
          )}
        </div>
      </div>
    </>
  );
}
