export default function BulletStepper({
  reservationStages,
  setReservationStages,
  activeStep,
  activeStepName,
  newStageSelectHandler,
  setShouldWiggle,
  shouldWiggle,
  cart,
}) {
  return (
    <nav
      className={`flex items-center justify-center ${
        shouldWiggle ? "wiggle" : ""
      }`}
      aria-label="Progress"
    >
      <p className="text-sm font-medium">
        {activeStep.id}: {activeStepName}
      </p>
      <ol
        role="list"
        className="ml-8 flex items-center space-x-5"
      >
        {reservationStages.map((step) => (
          <li key={step.name}>
            {step.active === true ? (
              <div
                onClick={() => {
                  newStageSelectHandler(step.id, cart);
                  setTimeout(() => setShouldWiggle(false), 500);
                }}
                className="block h-2.5 w-2.5 rounded-full bg-ttNavy-600 hover:bg-ttNavy-900"
              >
                <span className="sr-only">{step.name}</span>
              </div>
            ) : step.active === true ? (
              <div
                onClick={() => {
                  newStageSelectHandler(step.id, cart);
                  setTimeout(() => setShouldWiggle(false), 500);
                }}
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-ttNavy-200" />
                </span>
                <span
                  className="relative block h-2.5 w-2.5 rounded-full bg-ttNavy-600"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </div>
            ) : (
              <div
                onClick={() => {
                  newStageSelectHandler(step.id, cart);
                  setTimeout(() => setShouldWiggle(false), 500);
                }}
                className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
              >
                <span className="sr-only">{step.name}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
