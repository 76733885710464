import {useState} from "react";
import nhslsLogo from "../../assets/logos/nhsls_logo.png";
import hoganLaxLogo from "../../assets/logos/hoganLax_logo.png";
import annapolisHawksLogo from "../../assets/logos/annapolisHawks_logo.png";
import threeDLacrosseUpstateLogo from "../../assets/logos/3dLacrosseUpstate_logo.png";
import advncLogo from "../../assets/logos/advnc_logo.jpg";
import arcLacrosseLogo from "../../assets/logos/arcLacrosse_logo.png";
import fogCityLogo from "../../assets/logos/fogCity_logo.png";
import mountainWestLogo from "../../assets/logos/mountainWest_logo.png";
import nationalsLCLogo from "../../assets/logos/nationalsLC_logo.png";
import eliteTournamentsLogo from "../../assets/logos/eliteTournaments_logo.png";
import iwclaTournamentSeriesLogo from "../../assets/logos/iwlcaTournamentSeries_logo.jpeg";
import TestimonialModal from "./TestimonialModal";

export default function LogoCloud() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const logos = [
    nhslsLogo,
    hoganLaxLogo,
    annapolisHawksLogo,
    threeDLacrosseUpstateLogo,
    advncLogo,
    arcLacrosseLogo,
    fogCityLogo,
    mountainWestLogo,
    nationalsLCLogo,
    eliteTournamentsLogo,
    iwclaTournamentSeriesLogo,
  ];

  return (
    <div id="reviews" className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="relative overflow-hidden">
          {/* Outer wrapper to define the visible area of the carousel */}
          <div
            className="logo-carousel-wrapper"
            style={{
              width: "50%", // Adjust this width to show fewer logos
              margin: "0 auto", // Center the carousel within the parent container
              overflow: "hidden", // Hide overflow to create a clipping effect
            }}
          >
            {/* Inner carousel container with animation */}
            <div
              className="logo-carousel"
              style={{
                display: "flex",
                whiteSpace: "nowrap",
                animation: "scroll 20s linear infinite",
              }}
            >
              {/* Duplicate logos for seamless looping */}
              {[...logos, ...logos, ...logos, ...logos, ...logos, ...logos].map((logo, index) => (
                <img
                  key={index}
                  className="h-12 w-auto object-contain mx-4"
                  src={logo}
                  alt={`Logo ${index}`}
                  width={158}
                  height={48}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="mt-16 flex justify-center">
          <p className="relative rounded-full bg-gray-800 px-4 py-1.5 text-sm leading-6 text-gray-300">
            <span className="hidden md:inline">
              Trusted by the top teams and top tournaments around the country.{" "}
            </span>
            <button className="font-semibold text-white" onClick={() => setIsModalOpen(true)}>
              <span className="absolute inset-0" aria-hidden="true" /> Read our
              customer stories <span aria-hidden="true">&rarr;</span>
            </button>
          </p>
        </div>
        <TestimonialModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
      {/* CSS for scrolling */}
      <style>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
}
