import { generateClient, post } from "aws-amplify/api";
import { customAlphabet } from "nanoid";
import { nolookalikesSafe } from "nanoid-dictionary";
import {
  createCustomer,
  createOrder,
  createOrderItem,
} from "../graphql/mutations";
import { discountByCode } from "../graphql/queries";
import {
  getPackagePriceFromCart,
  getTotalAddOnPriceFromCart,
  getSubTotalWithDiscountFromCart,
} from "../utils/helpers";

const client = generateClient();

const createPaymentIntentMutation = `mutation CreatePaymentIntent($email: String!, $tournament: String!, $description: String!, $productIds: [ID!]!, $discountCode: String) {
  createPaymentIntent(email: $email, tournament: $tournament, description: $description, productIds: $productIds, discountCode: $discountCode) {
    clientSecret
    errors
  }
}`;

const sendContactUsEmailMutation = `mutation SendContactUsEmail($name: String!, $email: String!, $phone: String!, $webMessage: String!) {
  sendContactUsEmail(name: $name, email: $email, phone: $phone, webMessage: $webMessage) {
    success
    message
  }
}`;

async function createPaymentIntent(cart) {
  try {
    const restOperation = post({
      apiName: "tourneyTentsRestAPI",
      path: "/createPaymentIntent",
      options: {
        headers: {
          "Content-Type": "application/json", // Set the Content-Type header
        },
        body: { cart }, // No need to JSON stringify the request body
      },
    });
    const { body } = await restOperation.response;
    //  consume as a JSON:
    const jsonResponse = await body.json();

    // Assuming the response contains the clientSecret and errors, similar to your previous function
    return jsonResponse; // Access the data property of the response to get the payload
  } catch (error) {
    console.error("Error creating payment intent:", error);
    console.error("error.message", error.message);
    return { clientSecret: null, errors: [error.message] };
  }
}

const sendContactUsEmail = async (name, email, phone = "", webMessage) => {
  try {
    // Ensure variables are passed correctly
    const data = await client.graphql({
      query: sendContactUsEmailMutation,
      variables: { name, email, phone, webMessage },
    });

    const { sendContactUsEmail } = data.data;
    // console.log("sendContactUsEmail: ", sendContactUsEmail);
    // Assuming the response structure matches what you have
    const { success } = sendContactUsEmail;
    if (success) {
      // console.log("Email sent successfully");
      return {
        success: true,
        message: "Email sent successfully",
      };
    } else {
      // console.error("Failed to send the email");
      return {
        success: false,
        message: "No success: Failed to send the email",
      };
    }
  } catch (error) {
    // console.error("Error sending email through Lambda", error);
    return {
      success: false,
      message: "Error caught: Failed to send the email",
    };
  }
};

const addOrderItemToDB = async (orderId, productId, quantity, price) => {
  const newOrderItem = {
    id: customAlphabet(nolookalikesSafe, 11)(),
    orderId: orderId,
    productId: productId,
    quantity: quantity,
    price: price.toFixed(2),
  };

  try {
    const data = await client.graphql({
      query: createOrderItem,
      variables: {
        input: newOrderItem,
      },
    });
    const createOrderItemResponse = data.data.createOrderItem;

    return {
      success: true,
      message: "Order item added to DB",
      data: createOrderItemResponse,
    };
  } catch (error) {
    // console.error("Error adding order item to DB:", error);
    return { success: false, message: "Error adding order item to DB" };
  }
};

const addOrderToDB = async (
  orderId,
  customerId,
  orderItemId,
  cart,
  paymentIntent
) => {
  const localSalesTaxPct = cart.tournament.localSalesTax;
  const packagePrice = getPackagePriceFromCart(cart);
  const addOnTotalPrice = getTotalAddOnPriceFromCart(cart);
  const subTotalPrice = packagePrice + addOnTotalPrice;
  const {subTotalWithDiscount, amountSaved} = getSubTotalWithDiscountFromCart(cart);
  // TODO check if salesTaxAmount is correctly calculated here
  const salesTaxAmount = subTotalWithDiscount * localSalesTaxPct;
  // const totalSaleAmount = subTotalPrice + salesTaxAmount;

  const newOrder = {
    id: orderId,
    customerId: customerId,
    eventId: cart.tournament.id,
    stripePaymentIntentId: paymentIntent.id,
    stripePaymentStatus: paymentIntent.status,
    dateOfOrder: new Date().toISOString(),
    totalPrice: (paymentIntent.amount / 100).toFixed(2),
    salesTax: salesTaxAmount.toFixed(2),
    ...(cart.discount && cart.discount.id
      ? { discountId: cart.discount.id }
      : {}),
    // createdAt: new Date().toISOString(),
    // updatedAt: new Date().toISOString(),
  };

  try {
    const data = await client.graphql({
      query: createOrder,
      variables: {
        input: newOrder,
      },
    });
    const createOrderResponse = data.data.createOrder;
    return {
      success: true,
      message: "Order added to DB",
      data: createOrderResponse,
    };
  } catch (error) {
    // console.error("Error adding order to DB:", error);
    return { success: false, message: "Error adding order to DB" };
  }
};

const addCustomerToDB = async (team) => {
  const newCustomer = {
    id: customAlphabet(nolookalikesSafe, 11)(),
    teamName: team.teamName,
    teamDivision: team.teamDivision,
    tournamentName: team.tournamentName,
    tournamentCity: team.tournamentCity,
    tournamentDate: team.tournamentDate,
    teamYear: team.teamYear,
    teamColor: team.teamColor,
    firstName: team.firstName,
    lastName: team.lastName,
    email: team.email,
    phone: team.phone,
    preferredContactMethod: team.preferredContactMethod.toUpperCase(),
    addressLine1: team.addressLine1,
    addressLine2: team.addressLine2,
    city: team.city,
    state: team.state,
    postalCode: team.postalCode,
    country: team.country,
  };

  try {
    const data = await client.graphql({
      query: createCustomer,
      variables: {
        input: newCustomer,
      },
    });
    const createCustomerResponse = data.data.createCustomer;
    return {
      success: true,
      message: "Customer added to DB",
      data: createCustomerResponse,
    };
  } catch (error) {
    // console.error("Error adding customer to DB:", error);
    return { success: false, message: "Error adding customer to DB" };
  }
};

const getDiscountFromDBByCode = async (discountCode) => {
  try {
    const data = await client.graphql({
      query: discountByCode,
      variables: { code: discountCode },
    });

    const discounts = data.data.discountByCode.items;

    // This only returns the first discount found
    return discounts.length > 0 ? discounts[0] : null;
  } catch (error) {
    console.error("Error fetching discount from DB:", error);
    return null;
  }
};

export {
  createPaymentIntent,
  sendContactUsEmail,
  addOrderToDB,
  addCustomerToDB,
  addOrderItemToDB,
  getDiscountFromDBByCode,
};
