import React, { useState } from "react";
import {
  XMarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { Dialog } from "@headlessui/react";
import fogCityLogo from "../../assets/logos/fogCity_logo.png";
import mountainWestLogo from "../../assets/logos/mountainWest_logo.png";
import nhslsLogo from "../../assets/logos/nhsls_logo.png";
import hoganLaxLogo from "../../assets/logos/hoganLax_logo.png";
import annapolisHawksLogo from "../../assets/logos/annapolisHawks_logo.png";
import eliteTournamentsLogo from "../../assets/logos/eliteTournaments_logo.png";
import SingleTestimonial from "./SingleTestimonial";

const testimonials = [
  {
    id: 1,
    name: "Blake F.",
    affiliation: "Annapolis Hawks",
    image: annapolisHawksLogo,
    quote:
      "We used Tourney Tents this week for Naptown Challenge. I couldn't tell you how easy this was! We got here and setup was done, breakdown was done at the end of the day. Tables were out, coolers were here. I could not recommend them more -- Tourney Tents is your go to!",
  },
  // {
  //   id: 2,
  //   name: "John Doe",
  //   affiliation: "Elite Tournaments",
  //   image: eliteTournamentsLogo,
  //   quote: "This product has revolutionized our workflow!",
  // },
  {
    id: 3,
    name: "Peter L.",
    affiliation: "Fog City",
    image: fogCityLogo,
    quote:
      "The Tourney Tents team has been amazing. Their customer service is off the charts, and I'm fired up to work with them in the future!",
  },
  // {
  //   id: 4,
  //   name: "Jane Smith",
  //   affiliation: "NHSLS",
  //   image: nhslsLogo,
  //   quote: "Incredible results! Our team has never been more productive.",
  // },
  // {
  //   id: 5,
  //   name: "Jane Smith",
  //   affiliation: "Hogan Lax",
  //   image: hoganLaxLogo,
  //   quote: "Incredible results! Our team has never been more productive.",
  // },
  {
    id: 6,
    name: "Mario K.",
    affiliation: "Mountain West Prime",
    image: mountainWestLogo,
    quote:
      "Tourney Tents was a great team choice to hire! It was 95 degrees at Naptown Challenge, the kids and parents were protected from the heat, hydrated, and made the event a breeze. We arrived and everything was setup, at the end of the day we just walked away and they took care of everything. Certainly a must hire for every event!",
  },
];

export default function TestimonialModal({ isOpen, onClose }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextTestimonial = () => {
    setActiveIndex((current) => (current + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setActiveIndex(
      (current) => (current - 1 + testimonials.length) % testimonials.length
    );
  };

  const currentTestimonial = testimonials[activeIndex];

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-10">
      <Dialog.Backdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all border-8 border-ttNavy-600">
            <div className="absolute top-2 right-2">
              <button
                type="button"
                className="inline-flex rounded-md p-1.5 text-ttOrange-500 focus:outline-none focus:ring-2 focus:ring-ttOrange-600 focus:ring-offset-2 focus:ring-offset-gray-50"
                onClick={onClose}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
            <div className="relative flex items-center">
              <button
                onClick={prevTestimonial}
                className="absolute left-0 z-10 p-2 text-gray-400 hover:text-gray-600"
              >
                <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
              </button>
              <div className="flex-1">
                <SingleTestimonial
                  image={currentTestimonial.image}
                  quote={currentTestimonial.quote}
                  name={currentTestimonial.name}
                  affiliation={currentTestimonial.affiliation}
                />
              </div>
              <button
                onClick={nextTestimonial}
                className="absolute right-0 z-10 p-2 text-gray-400 hover:text-gray-600"
              >
                <ChevronRightIcon className="h-8 w-8" aria-hidden="true" />
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
