import { Link } from "react-router-dom";

const faqs = [
  {
    id: 1,
    question: "Where will my tent be set up?",
    answer:
      "We work with the tournament directors to find the best location for your tent.",
  },
  { id: 2,
    question: "This is a multi-day event. Will my tent move from day to day?",
    answer:
      "Yes! Tents will be set up daily for each day of the tournament at the venue where your team is scheduled.",
  },
  {
    id: 3,
    question: "Do you seriously set everything up and break everything down?",
    answer:
      "That's right! We handle everything from start to finish. You just show up and play. We'll take care of the rest.",
  },
  {
    id: 4,
    question: "When will I find out where my tent is located?",
    answer:
      "We will send you an email with the location of your tent about a week before your event. We will also provide you with a map of the event so you can find your tent easily.",
  },
  { id: 5,
    question: "Will my add-ons be delivered daily?",
    answer:
    "You will get any add-ons you order delivered to your tent each day!",
  }
];


export default function FAQ() {
  return (
    <div id="faq" className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        
        <div className="mt-8">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-8 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600">
          Have a different question and can’t find the answer you’re looking
          for?
        </p>
        <p className="text-gray-600">
          <Link
            to="/contact"
            className="font-semibold text-ttOrange-600 hover:text-ttOrange-500"
          >
            Reach out to our support team
          </Link>{" "}
          and we’ll get back to you as soon as we can.
        </p>
      </div>
    </div>
  );
}
