import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dialog } from "@headlessui/react";

export default function TermsModal({ isOpen, onClose }) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-5">
      <Dialog.Backdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-2 text-center">
          <Dialog.Panel className="fixed w-3/4 h-3/4 max-w-screen-lg max-h-screen-lg transform overflow-auto rounded-lg bg-white p-4 text-left shadow-xl transition-all border-8 border-ttNavy-600">
            <div className="sticky top-0 right-0 z-10 flex justify-end p-2 ">
              <button
                type="button"
                className="inline-flex rounded-md p-2 text-ttOrange-500 hover:text-ttOrange-600 focus:outline-none focus:ring-2 focus:ring-ttOrange-600 focus:ring-offset-2 focus:ring-offset-gray-50"
                onClick={onClose}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
            <div className="relative flex flex-col items-start p-4 bg-white rounded-md shadow-md space-y-4">
              <h1 className="text-2xl font-bold text-gray-900">
                Terms and Conditions of Use
              </h1>
              <p className="text-sm text-gray-500">
                Last Updated: August 27, 2024
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                1. Introduction
              </h3>
              <p className="text-gray-700 leading-relaxed">
                Welcome to Tourney Tents, LLC ("we," "us," or "our"). By using
                our services and renting our products, including tents, coolers,
                chairs, and ice, you ("Customer," "you," or "your") agree to
                abide by the following Terms and Conditions. These Terms govern
                the services provided and the use of our products and are
                legally binding. Please read them carefully before proceeding
                with your purchase.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                2. Rental Agreement
              </h3>
              <p className="text-gray-700 leading-relaxed">
                By completing your rental, you agree to:
                <ul className="list-disc list-inside ml-4">
                  <li>
                    Use the rented products only for their intended purpose.
                  </li>
                  <li>
                    Return the rented products in the same condition as
                    received, normal wear and tear excepted.
                  </li>
                  <li>
                    Be responsible for any damages or loss to the rented
                    products during the rental period.
                  </li>
                </ul>
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                3. Liability Waiver
              </h3>

              <h4 className="text-lg font-medium text-gray-700">
                3.1 Use of Products
              </h4>
              <p className="text-gray-700 leading-relaxed">
                You acknowledge that the use of rented products, including
                tents, coolers, chairs, and ice, involves certain risks. These
                risks include, but are not limited to, injuries resulting from
                the setup, use, or dismantling of the products, as well as
                environmental factors such as weather conditions.
              </p>

              <h4 className="text-lg font-medium text-gray-700">
                3.2 Disclaimer of Liability
              </h4>
              <p className="text-gray-700 leading-relaxed">
                Tourney Tents LLC shall not be held liable for any injuries,
                damages, or losses arising from the use of the rented products.
                This includes, but is not limited to, personal injury, property
                damage, or any other harm or loss, whether caused by negligence
                or otherwise.
              </p>

              <h4 className="text-lg font-medium text-gray-700">
                3.3 Customer Responsibility
              </h4>
              <p className="text-gray-700 leading-relaxed">
                You agree to take full responsibility for the safe use and
                operation of the rented products. You further agree to hold
                Tourney Tents LLC harmless from any claims or lawsuits brought
                against us as a result of your use of the rented products.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                4. Damages and Losses
              </h3>

              <h4 className="text-lg font-medium text-gray-700">
                4.1 Customer Responsibility for Damages
              </h4>
              <p className="text-gray-700 leading-relaxed">
                You agree to be financially responsible for any damages to or
                loss of the rented products during the rental period. This
                includes, but is not limited to, damage caused by improper use,
                accidents, or theft.
              </p>

              <h4 className="text-lg font-medium text-gray-700">
                4.2 Inspection and Assessment of Damages
              </h4>
              <p className="text-gray-700 leading-relaxed">
                Upon return of the rented products, Tourney Tents LLC will
                inspect the items for any damages or missing components. You
                will be notified of any identified damages or losses, and you
                agree to reimburse Tourney Tents LLC for the cost of repair or
                replacement.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                5. Service Period
              </h3>
              <p className="text-gray-700 leading-relaxed">
                The service period begins on the start date and time of the
                event that you have registered for and ends at the conclusion of
                said event.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                6. Cancellations and Refunds
              </h3>
              <p className="text-gray-700 leading-relaxed">
                Cancellations made 14 days before the scheduled rental date will
                receive a full refund. Cancellations made within 3 days of the
                rental date may be subject to a cancellation fee. No refunds
                will be issued for cancellations made on the rental date.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                7. Weather Policy
              </h3>
              <p className="text-gray-700 leading-relaxed">
                We understand that weather can significantly impact outdoor
                events, and your safety and the success of your event are our
                top priorities. We will make every effort to serve all scheduled
                events as planned, provided it is safe to do so, at our
                discretion. If an event must be rescheduled due to weather
                conditions, we will attempt to accommodate the new date, subject
                to availability. If we are unable to serve the rescheduled event
                or if you choose not to participate in it, a refund will be
                issued. However, please note that a 10% charge will be applied
                to cover our expenses. We appreciate your understanding and
                cooperation in these situations.
              </p>

              <p className="text-gray-700 leading-relaxed">
                We will notify you of any weather-related changes or
                cancellations, as soon as possible.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                8. Indemnification
              </h3>
              <p className="text-gray-700 leading-relaxed">
                You agree to indemnify, defend, and hold harmless Tourney Tents
                LLC, its officers, employees, and agents from and against any
                and all claims, liabilities, damages, losses, or expenses
                (including reasonable attorneys' fees) arising out of or in any
                way connected with your rental and use of the products.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                9. Governing Law
              </h3>
              <p className="text-gray-700 leading-relaxed">
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of the state of Maryland, without
                regard to its conflict of laws principles.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                10. Amendments
              </h3>
              <p className="text-gray-700 leading-relaxed">
                Tourney Tents LLC reserves the right to amend these Terms and
                Conditions at any time. Any changes will be effective
                immediately upon posting on our website or otherwise providing
                notice to you.
              </p>

              <h3 className="text-xl font-semibold text-gray-800">
                11. Contact Information
              </h3>
              <p className="text-gray-700 leading-relaxed">
                If you have any questions or concerns about these Terms and
                Conditions, please contact us at: <br />
                Tourney Tents LLC <br />
                tourneytentsmd@gmail.com
              </p>

              <h1 className="text-2xl font-bold text-gray-900">
                Acknowledgment
              </h1>
              <p className="text-gray-700 leading-relaxed">
                By proceeding with your purchase, you acknowledge that you have
                read, understood, and agree to be bound by these Terms and
                Conditions.
              </p>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
