import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function SlideoverCalculator({
  slideoverCalculatorOpen,
  setSlideoverCalculatorOpen,
}) {
  // State to store the number of people and selected package
  const [people, setPeople] = useState(20); // Default to 1 person
  const [packageType, setPackageType] = useState("standard"); // Default to standard package

  // Function to calculate price
  const calculatePrice = () => {
    const basePrice = packageType === "standard" ? 1000 : 1850;
    return basePrice / people;
  };

  return (
    <>
      <Transition.Root show={slideoverCalculatorOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setSlideoverCalculatorOpen}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-ttNavy-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            Savings Calculator
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-ttNavy-700 text-ttNavy-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setSlideoverCalculatorOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-ttBlue-300">
                            Calculate your cost per player on your team and see
                            your savings add up!
                          </p>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4 py-6 sm:px-6">
                        <div className="p-4">
                          <div>
                            <label
                              htmlFor="people-slider"
                              className="block text-sm font-medium text-gray-700"
                            >
                              How many players are on your team: {people}
                            </label>
                            <input
                              id="people-slider"
                              type="range"
                              min="1"
                              max="60"
                              value={people}
                              onChange={(e) =>
                                setPeople(parseInt(e.target.value))
                              }
                              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                            />
                          </div>
                          <div className="mt-4">
                            <div className="flex items-center mb-4">
                              <input
                                id="standard"
                                type="radio"
                                name="package"
                                value="standard"
                                checked={packageType === "standard"}
                                onChange={() => setPackageType("standard")}
                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                              />
                              <label
                                htmlFor="standard"
                                className="ml-2 block text-sm font-medium text-gray-700"
                              >
                                Standard Package ($1000)
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id="premium"
                                type="radio"
                                name="package"
                                value="premium"
                                checked={packageType === "premium"}
                                onChange={() => setPackageType("premium")}
                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                              />
                              <label
                                htmlFor="premium"
                                className="ml-2 block text-sm font-medium text-gray-700"
                              >
                                Premium Package ($1850)
                              </label>
                            </div>
                          </div>
                          <div className="mt-6">
                            <p className="text-lg font-semibold">
                              Price per player: ${calculatePrice().toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
