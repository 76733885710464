/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "tourneyTentsRestAPI",
            "endpoint": "https://xen4jnz0l2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://afd7bznsybfdxdoit2gx24ics4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tm6ndb6j6bgwvft5y7dtcqikl4",
    "aws_cognito_identity_pool_id": "us-east-1:f6cb1204-569d-43f1-8a29-ce855b49c4ec",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JFBzVNnjy",
    "aws_user_pools_web_client_id": "6a42bjh7o6p44a79c0vk91llk4",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tourneytents38f26911d4c54944a985552dc7f17097152148-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
