/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent(
    $email: String!
    $tournament: String!
    $description: String!
    $productIds: [ID!]!
    $discountCode: String
  ) {
    createPaymentIntent(
      email: $email
      tournament: $tournament
      description: $description
      productIds: $productIds
      discountCode: $discountCode
    ) {
      clientSecret
      errors
      __typename
    }
  }
`;
export const sendContactUsEmail = /* GraphQL */ `
  mutation SendContactUsEmail(
    $name: String!
    $email: String!
    $phone: String!
    $webMessage: String!
  ) {
    sendContactUsEmail(
      name: $name
      email: $email
      phone: $phone
      webMessage: $webMessage
    ) {
      success
      message
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      teamName
      tournamentName
      tournamentCity
      tournamentDate
      teamDivision
      teamYear
      teamColor
      firstName
      lastName
      email
      phone
      preferredContactMethod
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
      orders {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        teamName
        tournamentName
        tournamentCity
        tournamentDate
        teamDivision
        teamYear
        teamColor
        firstName
        lastName
        email
        phone
        preferredContactMethod
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      stripePaymentIntentId
      stripePaymentStatus
      stripeCustomerId
      dateOfOrder
      totalPrice
      salesTax
      discountId
      discount {
        id
        code
        description
        type
        value
        expirationDate
        usageLimit
        createdAt
        updatedAt
        productId
        eventId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      id
      orderId
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      quantity
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount(
    $input: CreateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    createDiscount(input: $input, condition: $condition) {
      id
      code
      description
      type
      value
      expirationDate
      usageLimit
      createdAt
      updatedAt
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      orders {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      subDescription
      features
      href
      imageSrc
      price
      events {
        nextToken
        __typename
      }
      orderItems {
        nextToken
        __typename
      }
      availability
      stillOffered
      discounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      subDescription
      features
      href
      imageSrc
      price
      events {
        nextToken
        __typename
      }
      orderItems {
        nextToken
        __typename
      }
      availability
      stillOffered
      discounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      subDescription
      features
      href
      imageSrc
      price
      events {
        nextToken
        __typename
      }
      orderItems {
        nextToken
        __typename
      }
      availability
      stillOffered
      discounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      city
      state
      dateString
      numberOfDays
      sport
      gender
      link
      imageAlt
      imageSrc
      startTimestamp
      closeRegistrationTimestamp
      singlePrice
      doublePrice
      localSalesTax
      products {
        nextToken
        __typename
      }
      orders {
        nextToken
        __typename
      }
      discounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      city
      state
      dateString
      numberOfDays
      sport
      gender
      link
      imageAlt
      imageSrc
      startTimestamp
      closeRegistrationTimestamp
      singlePrice
      doublePrice
      localSalesTax
      products {
        nextToken
        __typename
      }
      orders {
        nextToken
        __typename
      }
      discounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      city
      state
      dateString
      numberOfDays
      sport
      gender
      link
      imageAlt
      imageSrc
      startTimestamp
      closeRegistrationTimestamp
      singlePrice
      doublePrice
      localSalesTax
      products {
        nextToken
        __typename
      }
      orders {
        nextToken
        __typename
      }
      discounts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventProductAvailability = /* GraphQL */ `
  mutation CreateEventProductAvailability(
    $input: CreateEventProductAvailabilityInput!
    $condition: ModelEventProductAvailabilityConditionInput
  ) {
    createEventProductAvailability(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventProductAvailability = /* GraphQL */ `
  mutation UpdateEventProductAvailability(
    $input: UpdateEventProductAvailabilityInput!
    $condition: ModelEventProductAvailabilityConditionInput
  ) {
    updateEventProductAvailability(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventProductAvailability = /* GraphQL */ `
  mutation DeleteEventProductAvailability(
    $input: DeleteEventProductAvailabilityInput!
    $condition: ModelEventProductAvailabilityConditionInput
  ) {
    deleteEventProductAvailability(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      teamName
      tournamentName
      tournamentCity
      tournamentDate
      teamDivision
      teamYear
      teamColor
      firstName
      lastName
      email
      phone
      preferredContactMethod
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
      orders {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      teamName
      tournamentName
      tournamentCity
      tournamentDate
      teamDivision
      teamYear
      teamColor
      firstName
      lastName
      email
      phone
      preferredContactMethod
      addressLine1
      addressLine2
      city
      state
      postalCode
      country
      orders {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        teamName
        tournamentName
        tournamentCity
        tournamentDate
        teamDivision
        teamYear
        teamColor
        firstName
        lastName
        email
        phone
        preferredContactMethod
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      stripePaymentIntentId
      stripePaymentStatus
      stripeCustomerId
      dateOfOrder
      totalPrice
      salesTax
      discountId
      discount {
        id
        code
        description
        type
        value
        expirationDate
        usageLimit
        createdAt
        updatedAt
        productId
        eventId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        teamName
        tournamentName
        tournamentCity
        tournamentDate
        teamDivision
        teamYear
        teamColor
        firstName
        lastName
        email
        phone
        preferredContactMethod
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        createdAt
        updatedAt
        __typename
      }
      items {
        nextToken
        __typename
      }
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      stripePaymentIntentId
      stripePaymentStatus
      stripeCustomerId
      dateOfOrder
      totalPrice
      salesTax
      discountId
      discount {
        id
        code
        description
        type
        value
        expirationDate
        usageLimit
        createdAt
        updatedAt
        productId
        eventId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      orderId
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      quantity
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
      orderId
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      quantity
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount(
    $input: UpdateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    updateDiscount(input: $input, condition: $condition) {
      id
      code
      description
      type
      value
      expirationDate
      usageLimit
      createdAt
      updatedAt
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      orders {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount(
    $input: DeleteDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    deleteDiscount(input: $input, condition: $condition) {
      id
      code
      description
      type
      value
      expirationDate
      usageLimit
      createdAt
      updatedAt
      productId
      product {
        id
        name
        description
        subDescription
        features
        href
        imageSrc
        price
        availability
        stillOffered
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        name
        city
        state
        dateString
        numberOfDays
        sport
        gender
        link
        imageAlt
        imageSrc
        startTimestamp
        closeRegistrationTimestamp
        singlePrice
        doublePrice
        localSalesTax
        createdAt
        updatedAt
        __typename
      }
      orders {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      imageSrc
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      imageSrc
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      imageSrc
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
