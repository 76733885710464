import { Link } from "react-router-dom";

export default function TournamentCardList({
  sortedAndFilteredTournaments,
  cart,
  setCart,
  newStageSelectHandler,
}) {
  const handleSelectTournament = (tournament) => {
    // console.log("TournamentCardList>handleSelectTournament>cart: ", cart);
    // if the tournament is already selected, unselect it
    const newCart = { ...cart };
    if (tournament.id === cart.tournament.id) {
      newCart.tournament = {};
    } else {
      newCart.tournament = tournament;

      newStageSelectHandler("Step 2", newCart);
    }
    setCart(newCart);
  };

  return (
    <div className="bg-white">
      <div className="py-4 md:py-2">
        <div className="mx-auto">
        <p className="font-semibold text-sm text-gray-700" aria-hidden="true">
          Check back soon for an exciting 2025 lineup!{" "}
          </p>
          <p className="font-semibold text-sm text-gray-700" aria-hidden="true">
            Even if you don't see your tournament listed, it doesn't mean we
            can't help! If you're attending an event in the mid-Atlantic region
            and want our support,{" "}
            <Link to="/contact">
              <span className="text-sm text-ttOrange-500 hover:text-ttOrange-600">
                please let us know!{" "}
              </span>
              <span
                className="text-sm text-ttOrange-500 hover:text-ttOrange-600"
                aria-hidden="true"
              >
                &rarr;
              </span>
            </Link>
          </p>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {sortedAndFilteredTournaments.length > 0 &&
            sortedAndFilteredTournaments.map((tournament) => (
              <div
                key={tournament.name}
                className={`rounded-md p-1 ${
                  tournament.id === cart.tournament.id
                    ? "bg-ttBlue-100 border-2 border-ttBlue-500"
                    : "border border-gray-300"
                }`}
              >
                <div className="relative p-4">
                  <div className="relative h-72 w-full overflow-hidden rounded-lg">
                    <div>
                      <img
                        src={tournament.imageSrc}
                        alt={tournament.imageAlt}
                        className="h-full w-full object-cover object-center"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </div>
                  <div className="relative mt-4">
                    <h3 className="text-sm font-medium text-gray-900">
                      <a
                        href={tournament.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {tournament.name}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {tournament.city}, {tournament.state}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {tournament.dateString}
                    </p>
                  </div>
                </div>
                <div className="mt-2 ">
                  <div
                    className="relative flex items-center justify-center text-center rounded-md  hover:bg-ttNavy-400 bg-ttNavy-600 px-8 py-2 text-sm font-medium text-white"
                    onClick={() => handleSelectTournament(tournament)}
                  >
                    {tournament.id === cart.tournament.id
                      ? "Unselect this tournament"
                      : "Select this tournament"}
                    <span className="sr-only">, {tournament.name}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
