import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createPaymentIntent } from "../../api/apiCalls";

import CheckoutForm from "./CheckoutForm";
import CartSummaryContents from "./CartSummaryContents";
import { ClipLoader } from "react-spinners";

// Calling `loadStripe` outside of component’s render to avoid
// recreating the `Stripe` object on every render.
// INFO Stripe Test Key
// const testKey =
  // "pk_test_51Or377Esh8gyuMMaFmHOOVCkfKsezBNlvL1EQ3z7I0GiuesnIvjz04vUsSuZ4fBs8hD28HXhmPCXtLeavOE84MVI00Y6QJvn2u";
const liveKey =
  "pk_live_51Or377Esh8gyuMMaWd4seL2mvsCb5b9ymcTum8dz5a5vej7PUqbXGZAHEWfIEoNCb22m2cncEOz6UJ4MElnvgTRW001wN84IZD";
const stripePromise = loadStripe(liveKey);

export default function Payments({
  cart,
  setCart,
  setReservationStages,
  initialReservationStages,
  searchQuery,
  setSearchQuery,
  clientSecret,
}) {
  

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#7bafd4",
      colorBackground: "#ffffff",
      colorText: "#374151", // gray-700
      colorDanger: "#f84c1e", // ttOrange-500
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "0.375rem",
      // See all possible variables below //#E0E6EB
    },
    rules: {
      ".Tab": {
        border: "1px solid #374151", // gray-700
      },
      ".TabLabel": {
        color: "#9CA3AF", // gray-400
      },
      ".TabLabel--selected": {
        color: "#232d4b", // ttNavy-400
      },
      ".Tab:hover": {
        color: "#7bafd4", // #7bafd4 = ttBlue-500
        border: "1px solid #7bafd4",
      },
      ".Tab--selected": {
        border: "1px solid #7bafd4",
      },
      ".Input": {
        border: "1px solid #E0E6EB",
        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      },
      ".Input:focus": {
        border: "1px solid #7bafd4",
        boxShadow: "0 0 0 1px #7bafd4", // #7bafd4 = ttBlue-500
      },
      ".Input--invalid": {
        boxShadow: "0 0 0 2px var(--colorDanger)",
      },
      ".Input::placeholder": {
        color: "#bdbdbd", // gray-400
      },

      // See all supported class names and selector syntax below
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl pb-24 pt-16 lg:max-w-7xl ">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          {clientSecret ? (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                clientSecret={clientSecret}
                cart={cart}
                setReservationStages={setReservationStages}
                initialReservationStages={initialReservationStages}
                setCart={setCart}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Elements>
          ) : (
            <div className="mx-auto max-w-2xl pb-24 pt-16 lg:max-w-7xl flex flex-col justify-center items-center">
              <div>
                <ClipLoader size={80} color="#f9704b" />
              </div>
              <div>
                <h2 className="text-lg font-medium text-ttOrange-700">
                  Loading secure checkout ...
                </h2>
              </div>
            </div>
          )}
          {/* Cart Summary */}
          <div className="mt-10 lg:mt-0">
            <h2 className="text-lg font-medium text-gray-900">Cart summary</h2>

            <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
              <h3 className="sr-only">Items in your cart</h3>
              <CartSummaryContents cart={cart} setCart={setCart} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
