import { CheckIcon } from "@heroicons/react/24/outline";
import singleBundleImage from "../../assets/singleBundleField3.png";
import doubleBundleImage from "../../assets/doubleBundleField.png";

const tiers = [
  {
    name: "Standard Package",
    id: "WjKmpGRTqTP",
    href: "#",
    price: 1000,
    discountText: "",
    description:
      "Our premier concierge package. Everything you need for a successful tournament.",
    features: [
      "1 tent",
      "1 table",
      "2x 6-seat benches",
      "1 cooler with ice",
      "Set up / breakdown",
    ],
  },
  {
    name: "Double Package",
    id: "wqCMPGG7Q6b",
    href: "#",
    price: 1850,
    discountText: "Save more on your second set",
    description: "Two times the fun! Save more on your second package.",
    features: [
      "2 tents",
      "2 tables",
      "4x 6-seat benches",
      "2 coolers with ice",
      "Set up / breakdown",
    ],
  },
];

export default function Packages() {
  return (
    <div className="flow-root bg-white py-12">
      <div className="">
        {/* <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-xl font-semibold leading-7 text-ttBlue-600">
            Two package sizes available
          </h2>
        </div> */}
        <div className="mx-auto mt-4 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className="flex flex-col justify-between rounded-3xl p-6 shadow-xl sm:p-8 border bg-white border-gray-300"
              >
                {/* Centered and enlarged image at the top */}
                <div className="flex flex-col items-center">
                  <div className="mb-4">
                    <img
                      src={
                        tier.id === "WjKmpGRTqTP"
                          ? singleBundleImage
                          : doubleBundleImage
                      }
                      alt=""
                      className="h-48 w-auto mx-auto" // Increased height for prominence
                    />
                  </div>
                  <h3
                    id={tier.id}
                    className="text-2xl font-semibold leading-7 text-center text-ttNavy-600"
                  >
                    {tier.name}
                  </h3>
                  <p className="mt-4 text-center text-lg leading-7 text-gray-600">
                    {tier.description}
                  </p>
                </div>

                {/* Features list with consistent styling */}
                <ul
                  role="list"
                  className="mt-6 space-y-2 text-base leading-7 text-gray-600"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex items-center gap-x-3">
                      <CheckIcon
                        className="h-6 w-6 flex-none text-ttNavy-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
