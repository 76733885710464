import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ShoppingCart from "@mui/icons-material/ShoppingCartOutlined";
import Inventory2 from "@mui/icons-material/Inventory2Outlined";
import LocalShipping from "@mui/icons-material/LocalShippingOutlined";
import Construction from "@mui/icons-material/ConstructionOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import RoomIcon from "@mui/icons-material/Room";
import Recycling from "@mui/icons-material/RecyclingOutlined";
import Summarize from "@mui/icons-material/SummarizeOutlined";
import productImage from "../../assets/productBundle.png";
import Packages from "./Packages";

export default function Feature({
  slideoverCalculatorOpen,
  setSlideoverCalculatorOpen,
}) {
  const location = useLocation();

  const handleOpenSlideOver = () => {
    
    setSlideoverCalculatorOpen(true);
  };

  const features = [
    // {
    //   name: "Easy ordering.",
    //   description: "Purchase at the same time as tournament registration.",
    //   icon: ShoppingCart,
    // },
    // {
    //   name: "All-in-one package.",
    //   description: "Tent, cooler, chairs, table, trash can.",
    //   icon: Inventory2,
    // },
    // {
    //   name: "Delivery.",
    //   description:
    //     "New in town and need a tent? Don't waste time looking for one - just let us bring it to you.",
    //   icon: LocalShipping,
    // },
    {
      name: "Ideal location.",
      description:
        "We work with the tournament directors to set up your tent in a prime location so you can enjoy the tournament.",
      icon: RoomIcon,
      linkSpan: null,
    },
    {
      name: "Setup.",
      description:
        "Tired of hauling gear from the parking lot to the fields? Never again.",
      icon: Construction,
      linkSpan: null,
    },
    {
      name: "Take down.",
      description:
        "We'll break down everything and haul it away. When you're done, you're done.",
      icon: Recycling,
      linkSpan: null,
    },
    {
      name: "Cost-effective.",
      description: "Our packages save you hassle AND money!",
      icon: MonetizationOnOutlinedIcon,
      linkSpan: (
        <span
          className="text-ttOrange-500"
          onClick={() => handleOpenSlideOver()}
        >
          See how much this would save your team.
        </span>
      ),
    },
    // {
    //   name: "A la carte.",
    //   description: "Need to add more to your bundle? No problem.",
    //   icon: Summarize,
    // },
  ];

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div id="packages" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-xl font-semibold leading-7 text-ttBlue-600">
            Get your head in the game
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            No tent? No problem.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We offer two packages to make your tournament experience as easy as
            possible.{" "}
          </p>
        </div>
      </div>
      <Packages />
      {/* <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            src={productImage}
            alt="App screenshot"
            className="rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            width={2432}
            height={1442}
          /> 
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[5%]" />
          </div>
        </div>
      </div> */}
      <div className="mx-auto mt-4 max-w-7xl px-6 sm:mt-12 md:mt-8 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <feature.icon
                  className="absolute left-1 top-1 h-5 w-5 text-ttBlue-600"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>{" "}
              <dd className="inline">
                {feature.description} {feature.linkSpan}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="button hidden sm:mt-12 sm:flex sm:justify-center">
        <Link to="#process">
          <button
            type="button"
            className="relative rounded-full px-3 py-1 text-md leading-6 text-gray-900 ring-1 ring-ttNavy-500/10 hover:ring-ttNavy-500/20"
          >
            How does it work?{" "}
            <span
              className="font-semibold text-ttOrange-500"
              aria-hidden="true"
            >
              See the simple steps{" "}
            </span>{" "}
            <span aria-hidden="true">&rarr;</span>
          </button>
        </Link>
      </div>
    </div>
  );
}
