import { CheckIcon } from "@heroicons/react/20/solid";

const tiers = [
  {
    name: "Standard Package",
    id: "WjKmpGRTqTP",
    href: "#",
    discountText: "",
    description:
      "Our premier concierge package. Everything you need for a successful tournament.",
    features: [
      "1 tent",
      "1 table",
      "2x 6-seat benches",
      "1 cooler with ice",
      "Set up / breakdown",
    ],
    stillOffered: true,
    availability: "BUNDLE",
  },
  {
    name: "Double Package",
    id: "wqCMPGG7Q6b",
    href: "#",
    discountText: "Save more on  your second set",
    description: "Two times the fun! Save more on your second package.",
    features: [
      "2 tents",
      "2 tables",
      "4x 6-seat benches",
      "2 coolers with ice",
      "Set up / breakdown",
    ],
    stillOffered: true,
    availability: "BUNDLE",
  },
];

export default function PackageSelection({
  setCart,
  cart,
  newStageSelectHandler,
}) {
  const handleSelectPackage = (selectedPackage) => {
    // console.log("selectedPackage", selectedPackage);
    const newCart = { ...cart };

    // Check if the selected package is already in the cart, if so, unselect it
    if (selectedPackage.id === cart.package.id) {
      newCart.package = {};
    } else {
      newCart.package = selectedPackage;
    }

    setCart(newCart);

    // Only call newStageSelectHandler if newCart.package is not empty
    if (Object.keys(newCart.package).length > 0) {
      newStageSelectHandler("Step 3", newCart);
    }
  };

  //

  return (
    <div className="mx-auto py-4">
      <div className="mx-auto max-w-7xl pb-96 ">
        <p className="mt-4 text-gray-500">
          Choose your bundled package.
        </p>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={`flex flex-col justify-between rounded-3xl p-8 shadow-xl sm:p-10 ${
                    tier.id === cart.package.id
                      ? "bg-ttBlue-100 border-2 border-ttBlue-500"
                      : "border bg-white border-gray-300"
                  }`}
                >
                  <div>
                    <h3
                      id={tier.id}
                      className="text-base font-semibold leading-7 text-ttNavy-600"
                    >
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        $
                        {(tier.name === "Standard Package"
                          ? cart.tournament.singlePrice
                          : tier.name === "Double Package"
                          ? cart.tournament.doublePrice
                          : ""
                        ).toLocaleString()}
                      </span>
                      {/* <span className="text-base font-semibold leading-7 text-gray-600">
                        {tier.discountText}
                      </span> */}
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                    >
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-ttNavy-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div
                    onClick={() => handleSelectPackage(tier)}
                    aria-describedby={tier.id}
                    className="mt-8 block rounded-md bg-ttNavy-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-ttNavy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ttNavy-600"
                  >
                    {tier.id === cart.package.id
                      ? "Unselect this package"
                      : "Select this package"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
