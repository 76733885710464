import {
  MagnifyingGlassIcon,
  BarsArrowUpIcon,
  BarsArrowDownIcon,
} from "@heroicons/react/20/solid";

export default function SearchBar({
  searchQuery,
  setSearchQuery,
  sortBy,
  setSortBy,
}) {
  const toggleSortBy = () => {
    if (sortBy === "asc") {
      setSortBy("desc");
    } else {
      setSortBy("asc");
    }
  };

  return (
    <div className="mt-16 flex rounded-md shadow-sm">
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          className="block w-full rounded-t-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ttNavy-600 sm:text-sm sm:leading-6"
          placeholder="Search for your tournament"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </div>
    </div>
  );
}
